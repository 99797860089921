/**
 * Header component that displays the company logo.
 * It consists of a flexible layout that adjusts to its container.
 * @returns A React component rendering the header section.
 */
const Header = () => {
  return (
    <div className="flex justify-between text-base bg-white p-4">
      {/* <div className="text-3xl font-medium my-auto text-secondaryTextColor">
        Hello Steve
      </div> */}
      <div
        className={`m-2 ml-8 text-3xl font-extrabold flex justify-center`}
      >
        <img
          src={"/venuLogo.svg"}
          alt="CompanyLogo"
        />
      </div>
    </div>
  );
};

export default Header;
