// redux/slices/customerProfileSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerDetailsType } from '../types/customerAccount';

interface CustomerProfileState {
    currentCustomerDetails: CustomerDetailsType | null; // Ensure this is correct
}

const initialState: CustomerProfileState = {
    currentCustomerDetails: null,
};

const customerProfileSlice = createSlice({
    name: 'customerProfile',
    initialState,
    reducers: {
        setCustomerProfile(state, action: PayloadAction<CustomerDetailsType>) {
            state.currentCustomerDetails = action.payload; // Ensure you're setting this property
        },
    },
});

export const { setCustomerProfile } = customerProfileSlice.actions;
export default customerProfileSlice.reducer;
