import { ProgressSpinner } from "primereact/progressspinner";

/**
 * A component that displays a spinning loader.
 *
 * @returns {JSX.Element} The rendered spinning loader component.
 */
const SpinningLoader = () => {
    return (
        <div className="text-center w-8 h-8 m-auto">
            <ProgressSpinner
                strokeWidth="6"
                animationDuration="2s"
                aria-label="Loading"
                className="w-full h-full"
                pt={{
                    circle: { className: `stroke-white animate-none` }
                }}
            />
        </div>
    );
}

export default SpinningLoader;
