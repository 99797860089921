import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface DraggableModalState {
    show: boolean; 
    isMessage: boolean;
    statusComponentType: 'default' | 'redux' | null; 
    title: string;
    firstButton?: string | null;
}

const initialState: DraggableModalState = {
    show: false,
    isMessage: false,
    statusComponentType: null,
    title: '',
    firstButton: null,
};

const draggableModalSlice = createSlice({
    name: "draggableModal",
    initialState,
    reducers: {
        setShowModal(state, action: PayloadAction<boolean>) {
            state.show = action.payload;
        },
        setIsMessage(state, action: PayloadAction<boolean>) {
            state.isMessage = action.payload;
        },

        setStatusComponentType(state, action: PayloadAction<'default' | 'redux' | null>) {
            state.statusComponentType = action.payload;
        },
        setTitle(state, action: PayloadAction<string>) {
            state.title = action.payload;
        },
        setFirstButton(state, action: PayloadAction<string | null>) {
            state.firstButton = action.payload;
        },
        resetDraggableModal(state) {
            state.show = false;
            state.isMessage = false;
            state.statusComponentType = "redux";
            state.title = '';
            state.firstButton = null;
        },
    },
});

export const { 
    setShowModal, 
    setIsMessage, 
    setStatusComponentType, 
    setTitle, 
    setFirstButton, 
    resetDraggableModal 
} = draggableModalSlice.actions;

export default draggableModalSlice.reducer;
