import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/slices/userSlice";
import { UserStateType } from "../../redux/types/user";
import { useNavigate } from "react-router-dom";
import MessageModal from "../shared/MessageModal/MessageModal";
import Header from "../Header/Header";
import GoogleLoginButton from "../GoogleLoginButton/GoogleLoginButton";
import useHttp from "../../hooks/useHttp";
import { GET_TIMEZONE, LAST_LOGIN } from "../../constants/apiPaths";
import { ProgressSpinner } from "primereact/progressspinner";
import decodeJwt from "../../constants/decodeJWT";
import { setTimezone } from "../../redux/slices/timezoneDropdownSlice";
import { DEFAULT_TIMEZONE, DEFAULT_TIMEZONE_VALUE } from "../../constants/stringConstant";

/**
 * Login component that handles user login and displays the login interface.
 *
 * @returns {JSX.Element} The rendered Login component.
 */
const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [errorDetails, setErrorDetails] = useState({
        title: "Login failed!",
        message: "Sorry, an unexpected error occurred. Please try again later.",
    });

    const { apiService } = useHttp();
    const [isLoading, setIsLoading] = useState(false);

    /**
     * Handles successful login response.
     *
     * @param {any} res - The response object from the login attempt.
     */
    const handleOnSuccess = async (res: any) => {
        try {
            const profileObj = res.user;
            const tokenStore = res._tokenResponse;
            const userDetails = await decodeJwt(tokenStore.idToken);
            if (!userDetails?.email_verified_status) {
                handleOnFailure({ code: "auth/user-not-verified" });
                setIsLoading(false);
                return;
            }
            const userState: UserStateType = {
                isLoggedIn: true,
                authToken: tokenStore.oauthAccessToken,
                refreshToken: tokenStore.refreshToken,
                idToken: tokenStore.idToken,
                userDetails: {
                    userId: profileObj.uid,
                    googleId: profileObj.uid,
                    email: profileObj.email,
                    givenName: userDetails.user_name,
                    name: profileObj.displayName,
                    imageUrl: profileObj.photoURL,
                },
            };
            navigate("/dashboard");
            dispatch(loginUser(userState));
            const results = await Promise.allSettled([
                apiService(`${GET_TIMEZONE}`, { Authorization: `Bearer ${tokenStore.idToken}` }, "GET", null, { idToken: tokenStore.idToken }),
                apiService(`${LAST_LOGIN}`, { Authorization: `Bearer ${tokenStore.idToken}` }, "POST", {}, { idToken: tokenStore.idToken }),
            ]);
            const timezoneResult = results[0];
            if (timezoneResult.status === "fulfilled") {
                const timezone = timezoneResult.value;
                dispatch(setTimezone({ timezone: timezone?.data?.preferredTimeZone ?? DEFAULT_TIMEZONE, value: timezone?.data?.timezoneFormat ?? DEFAULT_TIMEZONE_VALUE }));
            } else {
                dispatch(setTimezone({ timezone: DEFAULT_TIMEZONE, value: DEFAULT_TIMEZONE_VALUE }));
            }
            console.clear();
        } catch (error) {
            setShowMessageModal(true);
        }
    };

    /**
     * Handles failed login response.
     *
     * @param {any} res - The response object from the login attempt.
     */
    const handleOnFailure = (res: any) => {
        res = JSON.parse(JSON.stringify(res));
        if (res?.code) {
            switch (res.code) {
                case "auth/popup-closed-by-user":
                    return;
                case "auth/admin-restricted-operation":
                    setErrorDetails({ title: "Invalid login!", message: "The email you tried to login is invalid." });
                    break;
                case "auth/user-not-verified":
                    setErrorDetails({ title: "Login failed!", message: "User email is not verified" });
                    break;
                case "auth/user-disabled":
                    setErrorDetails({ title: "Login failed!", message: "This email ID has been blocked." });
                    break;
                default:
                    setErrorDetails({ title: "Login failed!", message: "Sorry, an unexpected error occurred. Please try again later. " });
                    break;
            }
        }
        setShowMessageModal(true);
    };

    return (
        <div className="bg-disabledButtonBgColor w-full h-[100vh] flex flex-col">
            <Header />
            <div className="flex-grow flex items-center justify-center">
                <div className="w-min m-auto text-center p-16 px-40 max-md:w-10/12 max-md:py-8 max-sm:w-11/12 max-sm:py-8 shadow-none rounded-3xl bg-white whitespace-nowrap">
                    <div className="font-bold text-4xl text-secondaryTextColor">Welcome to</div>
                    <div className="font-bold text-4xl text-secondaryTextColor">Customer Support Dashboard</div>
                    <div className="my-16 max-sm:my-4">
                        <GoogleLoginButton handleOnSuccess={handleOnSuccess} handleOnFailure={handleOnFailure} setLoading={setIsLoading} />
                    </div>
                </div>
                {isLoading && (
                    <div className="spinner-overlay absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 z-50">
                        <ProgressSpinner />
                    </div>
                )}
            </div>
            <MessageModal show={showMessageModal} isSuccess={false} title={errorDetails.title} message={errorDetails.message} setShowModal={setShowMessageModal} />
        </div>
    );
};

export default Login;
