/**
 * Represents the feedback questions for user evaluation.
 * @type {Array<FeedbackQuestion>}
 */
export const FeedbackQuestionArray=[
    {
      "id":"1",
      "type": "radio",
      "question": "How effectively did the user follow instructions provided by you?",
      "options": [
          {
              "id": "1",
              "value": "Followed instructions easily",
              "title": "Followed instructions easily"
          },
          {
              "id": "2",
              "value": "Followed instructions but needed clarification",
              "title": "Followed instructions but needed clarification"
          },
          {
              "id": "3",
              "value": "Had difficulty following instructions",
              "title": "Had difficulty following instructions"
          },
          {
              "id": "4",
              "value": "Did not follow instructions",
              "title": "Did not follow instructions"
          },
      ]
    },
    {
      "id": "2",
      "type": "radio",
      "question": "How well do you think you were able to resolve the customer’s issue?",
      "options": [
          {
              "id": "1",
              "value": "Resolved the issue easily ",
              "title": "Resolved the issue easily "
          },
          {
              "id": "2",
              "value": "Had difficulty resolving the issue",
              "title": "Had difficulty resolving the issue"
          },
          {
              "id": "3",
              "value": "Managed to resolve most of the issues",
              "title": "Managed to resolve most of the issues"
          },
          {
              "id": "4",
              "value": "Unable to resolve the issue",
              "title": "Unable to resolve the issue"
          },
      ]
    },
    {
      "id": "3",
      "type": "radio",
      "question": "Were there any challenges or difficulties in resolving the user's issue? If yes, please specify.",
      "options": [
          {
              "id": "1",
              "value": "Yes",
              "title": "Yes"
          },
          {
              "id": "2",
              "value": "No",
              "title": "No"
          },
      ]
    }
  ];
  
  /**
   * @typedef {Object} FeedbackQuestion
   * @property {string} id - The unique identifier for the question.
   * @property {string} type - The type of question (e.g., radio).
   * @property {string} question - The question text to be presented.
   * @property {Array<Option>} options - The available options for the question.
   */
  
  /**
   * @typedef {Object} Option
   * @property {string} id - The unique identifier for the option.
   * @property {string} value - The display value of the option.
   * @property {string} title - The title of the option.
   */