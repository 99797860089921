import { NavLink, useLocation, useNavigate } from "react-router-dom";
import SIDEBAR_LINKS from "./SIDEBAR_NAVLINKS";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetCustomerAccount } from "../../redux/slices/customerAccountSlice";
import { resetSearchCustomer } from "../../redux/slices/searchCustomerSlice";
import { logoutUser } from "../../redux/slices/userSlice";
import { logout } from "../../utilities/firebase";
import MessageModal from "../shared/MessageModal/MessageModal";
import { RootState } from "../../redux/store";
import ReactHotkeys from "react-hot-keys";
import UserProfile from "./UserProfile";
import { PLATFORM_USER_MANNUAL } from "../../constants/stringConstant";
import Modal from "../shared/Modal/Modal";
import TimezoneModal from "./TimezoneModal";
import { clearAllCookies } from "../../utilities/resuableColumnFunctions";

/**
 * Props for the Sidebar component.
 *
 * @interface CustomSidebarProps
 * @property {boolean} sidebarState - Indicates whether the sidebar is open or closed.
 * @property {(e: boolean) => void} setSidebarState - Function to set the sidebar's open/closed state.
 * @property {number} roleId - The role ID of the current user.
 */
export interface CustomSidebarProps {
    sidebarState: boolean;
    setSidebarState: (e: boolean) => void;
    roleId: number;
}

/**
 * Sidebar component that contains navigation links and user options.
 *
 * @param {CustomSidebarProps} props - The properties for the Sidebar component.
 * @returns {JSX.Element} The rendered Sidebar component.
 */
export const Sidebar = ({ sidebarState, setSidebarState, roleId }: CustomSidebarProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { userDetails } = useSelector((state: RootState) => state.user);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showUserMannualModal, setShowUserMannualModal] = useState(false);
    const [showTimezoneModal, setShowTimezoneModal] = useState<boolean>(false);
    const { preferedTimezone } = useSelector((state: RootState) => state.timezone);

    const SIDEBAR_NAVLINKS = SIDEBAR_LINKS({ roleId });

    /**
     * Handles user logout and clears session data.
     */
    const logoutHandler = () => {
        clearAllCookies();
        sessionStorage.clear();
        dispatch(resetCustomerAccount());
        dispatch(resetSearchCustomer());
        dispatch(logoutUser());
        logout();
        navigate("/login");
    };

    useEffect(() => {
        setSidebarState(false);
    }, []);

    /**
     * Handles hotkey navigation based on key combinations.
     *
     * @param {string} keyName - The name of the key that was pressed.
     */
    const onHotKeyEnter = (keyName: string) => {
        switch (keyName) {
            case "shift+alt+s":
                !location.pathname.includes("/dashboard") && navigate("/dashboard");
                break;
            case "shift+alt+a":
                roleId <= 2 && navigate("/customerSupportAgents");
                break;
            case "shift+alt+m":
                roleId === 1 && navigate("/managers");
                break;
            case "shift+alt+c":
                navigate("/callActivity");
                break;
            case "alt+f":
            case "shift+alt+f":
                navigate("/faq");
                break;
        }
    };
    return (
        <div className="h-screen">
            <ReactHotkeys keyName="shift+alt+a,shift+alt+m,shift+alt+c,shift+alt+f,shift+alt+s,alt+f" onKeyDown={onHotKeyEnter} />
            <MessageModal
                show={showLogoutModal}
                setShowModal={setShowLogoutModal}
                title="Logout"
                message="Are you sure you want to logout?"
                secondButtonText="Logout"
                onClickSecondButton={logoutHandler}
                onClose={() => setSidebarState(false)}
            />
            <MessageModal
                show={showUserMannualModal}
                setShowModal={setShowUserMannualModal}
                title="Knowledge base"
                message={`Follow the link below for information and guidance on 
common customer queries.`}
                onClickFirstButton={() => {
                    setSidebarState(false);
                    window.open(PLATFORM_USER_MANNUAL, "_blank", "noopener,noreferrer");
                }}
                firstButtonText="Go to knowledge base"
                onClose={() => setSidebarState(false)}
            />
            {userDetails && (
                <Modal show={userDetails && showTimezoneModal} setShowModal={setShowTimezoneModal} onCloseModal={() => setSidebarState(false)}>
                    <TimezoneModal setShowModal={setShowTimezoneModal} userDetails={userDetails} setSidebarState={setSidebarState} />
                </Modal>
            )}
            <div className={`text-left bg-white relative flex flex-col h-full border-none shadow-none transition-all duration-[1200ms] ${sidebarState ? "w-64" : "w-24"} `}>
                <div className={`p-5 text-left mb-4 text-3xl ml-3 font-extrabold overflow-hidden mt-1`}>
                    <img className={`w-auto h-12 ${sidebarState && "min-w-[136px]"}`} src={sidebarState ? "/venuLogo.svg" : "/venu-logo-96x34 1.svg"} alt="CompanyLogo" />
                </div>
                <div className="px-5 content-center overflow-y-auto mt-1 overflow-x-hidden">
                    {SIDEBAR_NAVLINKS.map((item) => (
                        <NavLink
                            to={item.path}
                            key={item.id}
                            onClick={() => {
                                setSidebarState(false);
                                item.onClick && item.onClick();
                                setSidebarState(false);
                            }}
                        >
                            <div
                                className={`flex justify-between text-[0.95rem] px-[1.1rem] pt-2 pb-1 2xl:text-base rounded border-primaryBorderColor my-2 items-center font-semibold ${
                                    location.pathname.includes(item.path) ? " bg-secondaryBackground text-buttonBgColor" : "text-sidebarTextColor"
                                }`}
                            >
                                <div className="flex items-center mb-1">
                                    <div className="mr-1 my-auto">
                                        {item.descriptions ? (
                                            React.createElement(item.icons, { color: location.pathname.includes(item.path) ? "#00A88F" : "#898989" })
                                        ) : (
                                            <span className={"pi " + item.icons}></span>
                                        )}
                                    </div>
                                    <span className={`ml-2 whitespace-nowrap ${!sidebarState && "hidden"}`}> {item.title} </span>
                                </div>
                            </div>
                        </NavLink>
                    ))}
                    <button
                        className="flex justify-between text-[0.95rem] px-[1.1rem] pt-2 pb-1 2xl:text-base rounded border-primaryBorderColor items-center font-semibold text-sidebarTextColor cursor-pointer"
                        onClick={() => setShowUserMannualModal(true)}
                    >
                        <div className="flex items-center mb-1">
                            <div className="mr-1 my-auto">
                                <span className="pi pi-bookmark"></span>
                            </div>
                            {sidebarState && <span className="ml-2 whitespace-nowrap">Knowledge base</span>}
                        </div>
                    </button>
                    <button onClick={() => setShowLogoutModal(true)}>
                        <div
                            className={`flex justify-between text-[0.95rem] px-[1.1rem] pt-2 pb-1 2xl:text-base my-2 rounded border-primaryBorderColor items-center font-semibold ${
                                location.pathname.includes("/log-out") ? " bg-secondaryBackground text-buttonBgColor " : "text-sidebarTextColor"
                            }`}
                        >
                            <div className="flex items-center ">
                                <div className="mr-1 my-auto">
                                    <span className={"pi pi-power-off"}></span>
                                </div>
                                {sidebarState && <span className="ml-2 whitespace-nowrap">Logout</span>}
                            </div>
                        </div>
                    </button>
                </div>
                <div className="mt-auto mb-5 text-sidebarTextColor px-5">
                    {sidebarState && (
                        <div className="ml-2 flex items-center ">
                            <div className="mr-1 my-auto">
                                <span className={"pi pi-globe"}></span>
                            </div>
                            {sidebarState && <span className="ml-2 whitespace-nowrap text-base font-medium">Timezone</span>}
                        </div>
                    )}
                    <button
                        className={`bg-profileBackgroundColor p-4 px-1 flex text-center rounded-lg w-full ${sidebarState ? "justify-between items-center" : "justify-center"}`}
                        onClick={() => setShowTimezoneModal((state) => !state)}
                    >
                        {sidebarState ? (
                            <>
                                <span className="ml-2 whitespace-nowrap text-base text-left font-medium">{preferedTimezone?.split("-")[0]}</span>
                                <span className="text-end pi pi-angle-right"></span>
                            </>
                        ) : (
                            <i className="pi pi-globe" />
                        )}
                    </button>
                    {userDetails && <UserProfile sidebarState={sidebarState} userDetails={userDetails} roleId={roleId} />}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
