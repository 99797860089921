import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { preferedTimezoneDataType } from "../types/timezoneDropdown";

const initialState: preferedTimezoneDataType = {
    preferedTimezone: "",
    preferedTimezoneValue: "",
};

const timezoneDropdownSlice = createSlice({
    name: "timezone",
    initialState,
    reducers: {
        setTimezone: (state: preferedTimezoneDataType, action: PayloadAction<any>) => {
            state.preferedTimezone = action.payload.timezone;
            state.preferedTimezoneValue = action.payload.value;
        },
    },
});

export const { setTimezone } = timezoneDropdownSlice.actions;

export default timezoneDropdownSlice.reducer;
