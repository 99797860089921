import { DotLottiePlayer } from "@dotlottie/react-player";
import ReactHotkeys from "react-hot-keys";

/**
 * Props for the SuccessAnimationModal component.
 */
interface SuccessAnimationModalProps {
    /** 
     * Function to call when the modal is clicked.
     */
    onClick: () => void;
    
    /** 
     * Text to display indicating success.
     */
    isSuccessText: string;
}

/**
 * Component that displays a success animation modal with a success message.
 *
 * @param {SuccessAnimationModalProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered SuccessAnimationModal component.
 */
const SuccessAnimationModal = ({ onClick, isSuccessText }: SuccessAnimationModalProps) => {
    return (
        <div>
            <ReactHotkeys keyName="esc" onKeyDown={onClick} />
            <button className="z-[2000] fixed inset-0 bg-black opacity-30 cursor-default" onClick={onClick}></button>
            <div className={`z-[2000] fixed m-auto text-center top-1/2 bg-white rounded-2xl -translate-y-1/2 shadow-modal inset-x-0 w-[45%] max-h-[95vh] `}>
                <div className="text-center max-h-[90vh] py-4 overflow-auto m-auto text-black w-full relative">
                    <div className="flex justify-between mr-10">
                        <div className="text-4xl text-secondaryTextColor font-extrabold w-full ml-14 max-2xl:text-2xl"></div>
                        <button onClick={onClick} aria-label="closeSuccessModal">
                            <span className="pi pi-times text-primaryTextColor text-xl max-2xl:text-sm"></span>
                        </button>
                    </div>
                    <div>
                        <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
                        <div className="text-center m-auto">
                            <div className="w-[80px] h-[80px] 2xl:w-[120px] 2xl:h-[120px] m-auto mb-8">
                                <DotLottiePlayer src="/venuTickL.lottie" autoplay />
                            </div>
                            <div className="text-secondaryTextColor font-extrabold my-4 text-3xl 2xl:text-4xl">{isSuccessText}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessAnimationModal;
