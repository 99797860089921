/**
 * Requests a new ID token using the provided refresh token.
 *
 * This function interacts with Firebase's secure token service to refresh the user's authentication token.
 * The request is made to the `securetoken.googleapis.com` API using the provided `refreshToken`.
 *
 * @param {Object} param - The input parameter object.
 * @param {string} param.refreshToken - The refresh token used to obtain a new ID token.
 * @returns {Promise<Object | null>} - A promise that resolves to the new token data if successful, or `null` if an error occurs.
 *
 * @example
 * ```ts
 * const newTokenData = await refreshTokenEveryApiCall({ refreshToken: "some-refresh-token" });
 * if (newTokenData) {
 *   // Use the new token data
 * } else {
 *   // Handle the error
 * }
 * ```
 */
const refreshTokenEveryApiCall = async ({ refreshToken }: { refreshToken: string }) => {
    try {
        const response = await fetch(`https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_API_KEY}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: `grant_type=refresh_token&refresh_token=${refreshToken}`,
        });
        const newData = await response.json();
        return newData;
    } catch (error) {
        return null;
    }
};

export default refreshTokenEveryApiCall;
