/* eslint-disable @typescript-eslint/ban-types */
import { Calendar } from "primereact/calendar";
import { SearchInputType } from "../Input/Input";
import { useEffect, useRef, useState } from "react";

/**
 * Extended type for CalendarInput that omits the 'value' property from SearchInputType.
 *
 * @interface CalendarInputType
 * @extends {Omit<SearchInputType, "value">}
 * @property {Date | null} value - The selected date value.
 * @property {string} [dateFormat] - The format for displaying the date.
 * @property {string} [optionalText] - Optional text to display alongside the label.
 * @property {boolean} [allowFutureDates] - Whether to allow selection of future dates.
 * @property {boolean} [allowPastDates] - Whether to allow selection of past dates.
 */
interface CalendarInputType extends Omit<SearchInputType, "value"> {
  value: Date | null;
  dateFormat?: string;
  optionalText?: string;
  allowFutureDates?: boolean;
  allowPastDates?: boolean;
}

/**
 * CalendarInput component that wraps the PrimeReact Calendar component.
 *
 * @param {CalendarInputType} props - The properties for the CalendarInput component.
 * @returns {JSX.Element} The rendered CalendarInput component.
 */
const CalendarInput = ({
  id,
  name,
  placeholder,
  value,
  label,
  onChange = () => null,
  className,
  onFocus = () => null,
  isError,
  errorMessage,
  autofocus = false,
  dateFormat = "mm/dd/yy",
  optionalText,
  isReadOnly,
  allowFutureDates,
  allowPastDates,
}: CalendarInputType) => {
  const [showInlineCalendar, setShowInlineCalendar] = useState<boolean>(false);
  const calendarRef = useRef<HTMLDivElement>(null);

  /**
   * Handles changes to the date value.
   *
   * @param {any} e - The event from the calendar change.
   */
  const handleOnChange = (e: any) => {
    const oldValue = e.value;
    onChange(id, oldValue, true);
    setShowInlineCalendar((prevState) => !prevState);
  };

  /**
   * Toggles the visibility of the inline calendar.
   */
  const toggleInlineCalendar = () => {
    setShowInlineCalendar((prevState) => !prevState);
  };
  useEffect(() => {
    if (showInlineCalendar && calendarRef.current) {
      calendarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showInlineCalendar]);
  return (
    <div className={`w-full text-start ${className}`}>
      {label && (
        <div className="font-medium mb-5 text-lg max-2xl:mb-2 max-2xl:text-base flex items-center">
          {label}
          {optionalText && (
            <span className="text-optionalTextColor">{optionalText}</span>
          )}
        </div>
      )}
      <button
        className="w-full p-input-icon-left"
        onClick={toggleInlineCalendar}
      >
        <Calendar
          id={id}
          name={name}
          placeholder={placeholder}
          onChange={handleOnChange}
          value={value}
          onFocus={onFocus}
          autoFocus={autofocus}
          showIcon
          dateFormat={dateFormat}
          readOnlyInput
          className={`calendarInput w-full text-base font-normal rounded-md bg-gray-100 border border-primaryBorderColor focus:border-primaryBorderColor ${className} ${
            isReadOnly && "bg-[#F3F4F6] text-secondaryBlack opacity-70"
          } `}
          disabled
        />
      </button>
      {showInlineCalendar && (
        <div ref={calendarRef}>
          <Calendar
            onChange={handleOnChange}
            value={value}
            inline
            showWeek
            className="w-full mt-4"
            maxDate={!allowFutureDates ? new Date() : undefined} // Disable future dates if allowFutureDates is false
            minDate={!allowPastDates ? new Date() : undefined}
          />
        </div>
      )}
      {isError && errorMessage && (
        <div className="text-errorMessageColor text-left text-sm mb-2 mt-2">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default CalendarInput;
