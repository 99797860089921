import { useState } from "react";
import "./App.scss";
import Sidebar from "./components/Sidebar/SideBar";
import AllRoutes from "./routes/AllRoutes";
import Login from "./components/Login/Login";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import VerifyInvitedEmail from "./components/VerifyInvitedEmail/VerifyInvitedEmail";
import { AppDispatch, RootState } from "./redux/store";
import decodeJwt from "./constants/decodeJWT";
import Stepper from "./components/shared/Stepper/Stepper";
import DraggableModal from "./components/shared/DraggableModal/DraggableModal";
import { setIsSuccess, setIsSuccessText, resetStepperState } from "./redux/slices/stepperSlice";
import SuccessAnimationModal from "./components/shared/SuccessAnimation/SuccessAnimationModal";
import { resetDraggableModal } from "./redux/slices/draggableModalSlice";

/**
 * The main App component that serves as the entry point for the React application.
 *
 * This component manages authentication checks, renders routes for logged-in and logged-out users,
 * and includes the sidebar, modals, and success animations.
 *
 * @returns {JSX.Element} The rendered App component.
 */
function App(): JSX.Element {
    // State for toggling the sidebar visibility
    const [showSideBar, setShowSideBar] = useState(false);

    // Select necessary values from the Redux store
    const { isLoggedIn, idToken } = useSelector((state: RootState) => state.user);

    // Decode JWT to extract user role information
    const decodedData = decodeJwt(idToken || "");

    // Default roleId is 3, overwrite if roleId exists in decoded token
    let roleId = 3;
    if (decodedData?.roles[0]?.roleId) {
        roleId = +decodedData?.roles[0]?.roleId;
    }

    // Dispatch function for interacting with Redux
    const dispatch = useDispatch<AppDispatch>();

    // Select stepper modal states from the Redux store
    const { isSuccess, isSuccessText } = useSelector((state: RootState) => state.stepperModal);

    return (
        <div>
            {!isLoggedIn ? (
                // Routes for non-logged-in users
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/verify/user" element={<VerifyInvitedEmail />} />
                    <Route path="/reset-password/*" element={<div>Hi this is reset password page</div>} />
                    <Route path="/*" element={<Navigate to="/" />} />
                </Routes>
            ) : (
                // Layout for logged-in users with sidebar and routes
                <div className="flex">
                    <div>
                        {isSuccess && (
                            <SuccessAnimationModal
                                onClick={() => {
                                    dispatch(setIsSuccess(false));
                                    dispatch(setIsSuccessText(""));
                                }}
                                isSuccessText={isSuccessText}
                            />
                        )}
                        <DraggableModal
                            onCloseModal={() => {
                                dispatch(resetDraggableModal());
                                dispatch(resetStepperState());
                            }}
                        >
                            <Stepper />
                        </DraggableModal>
                    </div>

                    {/* Sidebar Component */}
                    <div
                        role="menu"
                        className={`fixed top-0 left-0 min-h-screen ${showSideBar ? "z-50" : "z-10"}`}
                        onMouseEnter={() => setShowSideBar(true)}
                        onMouseLeave={() => setShowSideBar(false)}
                        tabIndex={0}
                    >
                        <Sidebar sidebarState={showSideBar} setSidebarState={setShowSideBar} roleId={roleId} />
                    </div>

                    {/* Main content area */}
                    <div className="w-full ml-24 flex-1">
                        <AllRoutes roleId={roleId} />
                    </div>
                </div>
            )}
        </div>
    );
}

export default App;
