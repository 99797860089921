import { useEffect, useState } from "react";
import { UserDataType } from "../../redux/types/user";
import roles from "../../constants/roles";
import { capitalFirstLetter } from "../../utilities/capitalFirstLetter";

/**
 * Props for the UserProfile component.
 *
 * @interface UserProfileProps
 * @property {boolean} [sidebarState=false] - Indicates whether the sidebar is open or closed.
 * @property {UserDataType} userDetails - Details of the user to display in the profile.
 * @property {number} roleId - The role ID of the user.
 */

/**
 * UserProfile component that displays user information.
 *
 * @param {UserProfileProps} props - The properties for the component.
 * @returns {JSX.Element} The rendered UserProfile component.
 */
const UserProfile = ({ sidebarState = false, userDetails, roleId }: { sidebarState?: boolean; userDetails: UserDataType; roleId: number }) => {
    const [expandProfile, setExpandProfile] = useState(false);

    useEffect(() => {
        if (!sidebarState) {
            setExpandProfile(false);
        }
    }, [sidebarState]);

    return (
        <button
            className={`bg-profileBackgroundColor p-2 px-1 flex justify-between text-center rounded-lg mt-5 ${
                expandProfile ? "items-end bg-white shadow-profileCard absolute z-10 bottom-0 mr-5 mb-5" : "items-baseline relative z-1 w-full"
            }`}
            onClick={() => setExpandProfile((state) => !state)}
            onBlur={() => setExpandProfile(false)}
        >
            <div className={expandProfile ? "cursor-default" : "flex justify-start items-baseline "}>
                <div className="bg-secondaryTextColor text-white rounded-full p-1 font-medium w-9 text-lg text-center items-base">{userDetails?.givenName?.charAt(0).toUpperCase()}</div>
                {sidebarState &&
                    (expandProfile ? (
                        <div className="mt-3 text-left text-wrap">
                            <h1 className="text-secondaryTextColor font-bold text-xl whitespace-nowrap">Hi {capitalFirstLetter(userDetails.givenName).split(" ")[0]}! </h1>
                            <div className="mt-3 text-sm">Email</div>
                            <div className="w-full text-sm font-semibold break-all -mt-1">{userDetails.email}</div>
                            <div className="mt-2 text-sm">Role</div>
                            <span className="font-semibold text-sm whitespace-nowrap -mt-1">{roles[roleId]}</span>
                            <div className="mb-8"></div>
                        </div>
                    ) : (
                        <div className="ml-2 text-primaryTextColor whitespace-nowrap">{roles[roleId]}</div>
                    ))}
            </div>
            <div>
                <span className={`pi ${expandProfile ? "pi-angle-down bg-profileBackgroundColor p-2" : "pi-angle-up pr-1"}  text-xs`}></span>
            </div>
        </button>
    );
};

export default UserProfile;
