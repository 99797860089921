/* eslint-disable @typescript-eslint/ban-types */
import { useEffect } from "react";
import Input from "../../../../shared/Input/Input";
import useHttp from "../../../../../hooks/useHttp";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import useForm from "../../../../../hooks/useForm";
import { CREATE_CALL_SUMMARY } from "../../../../../constants/apiPaths";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { fetchMetadata } from "../../../../../redux/slices/metadataSlice";
import ReactHotkeys from "react-hot-keys";
import SpinningLoader from "../../../../shared/Loader/SpinningLoader";
import InputArea from "../../../../shared/InputArea/InputArea";
import CalendarInput from "../../../../shared/Calendar/Calendar";
import { formatMobilePhoneBody } from "../../../../../utilities/resuableColumnFunctions";
import moment from "moment";
import { fetchUserCallSummary } from "../../../../../redux/slices/customerAccountSlice";
import { CHECK_STATUS, DEFAULT_PAGE_LIMIT } from "../../../../../constants/stringConstant";

/** Initial values for the call summary form */
const INITIAL_FORM_VALUE = {
    notVerifiedReason: "",
    selectedIssue: null,
    callReason: "",
    status: null,
    actionTaken: "",
    unResolvedResolution: null,
    otherUnresolvedReason: "",
    issueDate: null,
};

/** Initial error states for the call summary form */
const INITIAL_FORM_ERROR = {
    notVerifiedReason: false,
    selectedIssue: true,
    callReason: true,
    status: true,
    actionTaken: false,
    unResolvedResolution: false,
    otherUnresolvedReason: false,
    issueDate: true,
};

/**
 * Props for the CallSummaryModal component.
 *
 * @param props - Object containing the following properties:
 *  - verified: Indicates if the call was verified.
 *  - setIsMessage: Function to set message visibility.
 *  - setMessage: Function to set the message content.
 *  - setIsSuccess: Function to set success state.
 *  - setIsSuccessText: Function to set success message text.
 *  - setShowModal: Function to control modal visibility.
 *  - showNextStep: Function to proceed to the next step.
 *  - showOnlyCallSummary: Boolean indicating if only call summary should be shown.
 *  - setCallId: Function to set the call ID.
 *  - caseModalValue: Value indicating the case modal type (e.g., "call" or "email").
 * @returns {JSX.Element} A JSX element representing the CallSummaryModal component.
 */
const CallSummaryModal = ({
    verified,
    setIsMessage,
    setIsSuccess,
    setIsSuccessText,
    setMessage,
    setShowModal,
    showNextStep,
    showOnlyCallSummary,
    setCallId,
    caseModalValue,
}: {
    verified: boolean;
    showOnlyCallSummary: boolean;
    setIsMessage: Function;
    setMessage: Function;
    setIsSuccess: Function;
    setIsSuccessText: Function;
    setShowModal: Function;
    showNextStep: Function;
    setCallId: Function;
    caseModalValue: string;
}) => {
    const { formValues, formErrors, showFormErrors, setShowFormErrors, onTextChange, onSelectChange, updateFormError } = useForm(INITIAL_FORM_VALUE, INITIAL_FORM_ERROR);
    const { isLoading, apiService } = useHttp();
    const dispatch = useDispatch<AppDispatch>();
    const { issueTypes, issueStatus, resolution_action } = useSelector((state: RootState) => state.metadata);
    const { currentCustomerDetails } = useSelector((state: RootState) => state.customerProfile);
    const mobilePhone = currentCustomerDetails?.phoneNumber ? formatMobilePhoneBody("1" + currentCustomerDetails.phoneNumber) : "Not available";

    useEffect(() => {
        dispatch(fetchMetadata());
        if (!verified) updateFormError("notVerifiedReason", true);
        if (caseModalValue === "email") {
            updateFormError("notVerifiedReason", false);
            updateFormError("issueDate", false);
        }
    }, [dispatch, verified, caseModalValue]);

    /**
     * Handles the saving of the call summary.
     *
     * @returns {Promise<void>} - A promise that resolves when the save is complete.
     */
    const handleSave = async () => {
        setShowFormErrors(true);
        if (!Object.values(formErrors).some((error) => error)) {
            try {
                const body = createRequestBody();
                await saveCallSummary(body);
            } catch (error: any) {
                handleSaveError(error);
            }
        }
    };

    /**
     * Creates the request body for saving the call summary.
     *
     * @returns {object} - The formatted request body.
     */
    const createRequestBody = () => {
        const formattedDate = moment(formValues.issueDate).format("MM-DD-YYYY");
        return {
            callReason: formValues.callReason,
            userId: currentCustomerDetails?.userId,
            statusId: formValues.status,
            issueId: formValues.selectedIssue,
            actionTaken: getActionTaken(),
            verified: caseModalValue === "call" ? verified : false,
            notVerifiedReason: getNotVerifiedReason(),
            ...getResolutionAction(),
            issueDate: formValues.issueDate ? formattedDate : null,
            modeOfContact: caseModalValue,
        };
    };

    /**
     * Gets the action taken based on the issue status.
     *
     * @returns {string | null} - The action taken or null if not applicable.
     */
    const getActionTaken = () => {
        return issueStatus.find((issue) => issue.id == formValues.status)?.key === "ST_R" ? formValues.actionTaken : null;
    };

    /**
     * Gets the reason for not verifying the call.
     *
     * @returns {string | null} - The reason or null if not applicable.
     */
    const getNotVerifiedReason = () => {
        if (caseModalValue === "call") {
            return verified ? null : formValues.notVerifiedReason;
        }
        return null;
    };

    /**
     * Gets the resolution action based on the selected status.
     *
     * @returns {object} - An object containing resolution action details.
     */
    const getResolutionAction = () => {
        const resolutionAction = issueStatus.find((issue) => issue.id == formValues.status)?.key === "ST_UR" ? { resolutionActionId: formValues.unResolvedResolution } : {};
        const otherUnresolvedReason =
            resolution_action.find((issue) => issue.id == formValues.unResolvedResolution)?.value === "Other" ? { otherUnresolvedReason: formValues.otherUnresolvedReason } : {};
        return { ...resolutionAction, ...otherUnresolvedReason };
    };

    /**
     * Saves the call summary using the provided request body.
     *
     * @param body - The request body for the API call.
     * @returns {Promise<void>} - A promise that resolves when the save is complete.
     */
    const saveCallSummary = async (body: any) => {
        const resp = await apiService(`${CREATE_CALL_SUMMARY}`, {}, "POST", body);
        setCallId(resp.data.id);
        setIsMessage(false);
        dispatch(fetchUserCallSummary({ page: 0, rows: DEFAULT_PAGE_LIMIT, userId: currentCustomerDetails?.userId, isDeregisteredUser: currentCustomerDetails?.status === CHECK_STATUS }));
        if (showOnlyCallSummary) {
            setIsSuccess(true);
            setIsSuccessText("Case summary saved successfully");
            setShowModal(false);
        } else {
            showNextStep();
        }
    };

    /**
     * Handles any errors that occur during the save process.
     *
     * @param error - The error object.
     */
    const handleSaveError = (error: any) => {
        setIsMessage(true);
        setMessage(error.message ?? "There was some error in adding case summary please try again!", "error");
    };

    /**
     * Renders the input for the not verified reason.
     *
     * @returns {JSX.Element | null} - The rendered input or null.
     */
    const renderNotVerifiedReason = () =>
        !verified &&
        caseModalValue !== "email" && (
            <div className="mb-8 max-2xl:mb-4">
                <div className="flex items-center mb-5 max-2xl:mb-2">
                    <i className="pi pi-info-circle mr-2" />
                    <div className="text-left font-light text-base">The call was not verified. Please add a reason for the same</div>
                </div>
                <Input
                    id="notVerifiedReason"
                    name="notVerifiedReason"
                    value={formValues.notVerifiedReason}
                    onChange={onTextChange}
                    placeholder="Please enter the reason"
                    className={formErrors.notVerifiedReason && showFormErrors ? "p-invalid " : ""}
                    isError={formErrors.notVerifiedReason && showFormErrors}
                    errorMessage="Please provide the reason"
                    autofocus
                />
            </div>
        );

    /**
     * Renders the dropdown for selecting the issue category.
     *
     * @returns {JSX.Element} - The rendered dropdown component.
     */
    const renderIssueCategoryDropdown = () => (
        <div className="mb-8 max-2xl:mb-4">
            <div className="mb-5 text-left font-medium text-lg max-2xl:mb-2 max-2xl:text-base">What is the issue category?</div>
            <Dropdown
                value={formValues.selectedIssue}
                onChange={(e: DropdownChangeEvent) => onSelectChange("selectedIssue", e.target.value)}
                options={issueTypes.map((issue: any) => ({ label: issue.value, value: issue.id }))}
                optionLabel="label"
                placeholder="Select an issue"
                className={`w-full text-left ${formErrors.selectedIssue && showFormErrors ? "p-invalid " : ""}`}
                pt={{
                    panel: {
                        style: { maxWidth: "300px" },
                    },
                }}
                appendTo={document.body}
            />
            {formErrors.selectedIssue && showFormErrors && <div className="text-errorMessageColor text-left text-sm mb-2 mt-2"> Please select an issue </div>}
        </div>
    );

    /**
     * Renders the input area for the reason for contact.
     *
     * @returns {JSX.Element} - The rendered input area component.
     */
    const renderReasonForContact = () => (
        <div className="mb-8 max-2xl:mb-4">
            <InputArea
                label="Reason for contact"
                id="callReason"
                name="callReason"
                placeholder="Please enter the reason"
                value={formValues.callReason}
                onChange={onTextChange}
                className={formErrors.callReason && showFormErrors ? "p-invalid" : ""}
                isError={formErrors.callReason && showFormErrors}
                errorMessage="Please provide the case reason"
            />
        </div>
    );

    /**
     * Renders the calendar input for the issue date.
     *
     * @returns {JSX.Element} - The rendered calendar input component.
     */
    const renderCalendarInput = () => (
        <div className="mb-8 max-2xl:mb-4">
            <CalendarInput
                label={`Since when is the user facing this issue?`}
                id="issueDate"
                name="issueDate"
                value={formValues.issueDate}
                onChange={onTextChange}
                placeholder="Select a date"
                className={formErrors.issueDate && showFormErrors ? "p-invalid " : ""}
                isError={formErrors.issueDate && showFormErrors}
                errorMessage="Please provide the date"
                optionalText={caseModalValue === "email" ? "(optional)" : undefined}
                allowPastDates={true}
            />
        </div>
    );

    /**
     * Renders the dropdown for selecting the status of the case.
     *
     * @returns {JSX.Element} - The rendered status input component.
     */
    const renderStatusInput = () => (
        <div className="mb-8 max-2xl:mb-4">
            <div className="mb-5 text-left font-medium text-lg max-2xl:mb-2 max-2xl:text-base">Status of the case</div>
            <Dropdown
                value={formValues.status}
                onChange={(e: DropdownChangeEvent) => {
                    onSelectChange("status", e.value);
                    if (issueStatus.find((issue) => issue.id == e.value)?.key === "ST_R") {
                        updateFormError("actionTaken", true);
                        updateFormError("unResolvedResolution", false);
                        onSelectChange("unResolvedResolution", "");
                        onTextChange("actionTaken", "", true);
                    } else {
                        updateFormError("actionTaken", false);
                        onSelectChange("unResolvedResolution", "");
                        updateFormError("unResolvedResolution", true);
                    }
                }}
                options={issueStatus.map((status: any) => ({ label: status.value, value: status.id }))}
                optionLabel="label"
                placeholder="Select status"
                className={`w-full text-left ${formErrors.status && showFormErrors ? "p-invalid " : ""}`}
            />
            {formErrors.status && showFormErrors && <div className=" text-errorMessageColor text-left text-sm mb-2 mt-2">Please select a status</div>}
        </div>
    );
    return (
        <div className="mt-8 2xl:mt-12 w-3/4 2xl:w-3/5 text-center m-auto">
            <ReactHotkeys keyName="alt+s" onKeyDown={handleSave} />
            <div className="flex justify-between">
                <span className="font-medium text-lg max-2xl:text-base mb-4">{caseModalValue === "email" ? "Email ID" : "Mobile number"}</span>
                <span className="text-optionalTextColor">{caseModalValue === "email" ? currentCustomerDetails?.email : mobilePhone}</span>
            </div>
            {renderNotVerifiedReason()}
            {renderIssueCategoryDropdown()}
            {renderReasonForContact()}
            {renderCalendarInput()}
            {renderStatusInput()}
            {issueStatus.find((issue) => issue.id == formValues.status)?.key === "ST_R" && (
                <div className="mb-8 max-2xl:mb-4">
                    <InputArea
                        label="Action taken"
                        id="actionTaken"
                        name="actionTaken"
                        value={formValues.actionTaken}
                        onChange={onTextChange}
                        placeholder="Please enter the action you took or solution provided"
                        className={formErrors.actionTaken && showFormErrors ? "p-invalid " : ""}
                        isError={formErrors.actionTaken && showFormErrors}
                        errorMessage="Please provide the action taken"
                    />
                </div>
            )}
            {issueStatus.find((issue) => issue.id == formValues.status)?.key === "ST_UR" && (
                <div className="mb-8 max-2xl:mb-4">
                    <div className="mb-5 text-left font-medium text-lg max-2xl:mb-2 max-2xl:text-base">Action Taken</div>
                    <Dropdown
                        value={formValues.unResolvedResolution}
                        onChange={(e: DropdownChangeEvent) => {
                            onSelectChange("unResolvedResolution", e.value);
                            if (resolution_action.find((issue) => issue.id == e.value)?.value === "Other") {
                                updateFormError("otherUnresolvedReason", true);
                                onTextChange("otherUnresolvedReason", "", true);
                            } else {
                                updateFormError("otherUnresolvedReason", false);
                            }
                        }}
                        options={resolution_action.map((action: any) => ({ label: action.value, value: action.id }))}
                        optionLabel="label"
                        placeholder="Resolution action"
                        className={`w-full text-left ${formErrors.unResolvedResolution && showFormErrors ? "p-invalid " : ""}`}
                        pt={{
                            wrapper: {
                                style: { maxHeight: "300px" },
                            },
                        }}
                    />
                    {formErrors.unResolvedResolution && showFormErrors && <div className=" text-errorMessageColor text-left text-sm mb-2 mt-2">Please select action taken</div>}
                </div>
            )}
            {resolution_action.find((issue) => issue.id == formValues.unResolvedResolution)?.value === "Other" && (
                <div className="mb-8 max-2xl:mb-4">
                    <InputArea
                        id="otherUnresolvedReason"
                        name="otherUnresolvedReason"
                        value={formValues.otherUnresolvedReason}
                        onChange={onTextChange}
                        placeholder="Write the other option to resolve"
                        className={formErrors.otherUnresolvedReason && showFormErrors ? "p-invalid " : ""}
                        isError={formErrors.otherUnresolvedReason && showFormErrors}
                        errorMessage="Please enter the action taken"
                    />
                </div>
            )}
            <div className="w-full text-center mt-12 max-2xl:mt-8">
                <button
                    className={`py-4 w-3/5 rounded-full font-semibold whitespace-nowrap mx-auto bg-buttonBgColor text-white max-2xl:py-3`}
                    onClick={handleSave}
                    disabled={isLoading}
                    aria-label="submitCallSummary"
                >
                    {isLoading ? <SpinningLoader /> : <>Save</>}
                </button>
            </div>
        </div>
    );
};
export default CallSummaryModal;
