/* eslint-disable @typescript-eslint/ban-types */ 
import { InputText } from "primereact/inputtext";
import { FocusEventHandler } from "react";
import validate from "../../../utilities/validate";
import PhoneInput from "react-phone-input-2";

/**
 * Type definition for the SearchInput component props.
 *
 * @type {SearchInputType}
 * @property {string} id - The unique identifier for the input.
 * @property {string} name - The name of the input element.
 * @property {string} [placeholder] - The placeholder text for the input.
 * @property {string} value - The current value of the input.
 * @property {string} [label] - The label to display above the input.
 * @property {string} [className] - Additional CSS classes for the input.
 * @property {string} [type] - The type of the input (e.g., text, phone).
 * @property {Function} [onChange] - Callback function triggered on input change.
 * @property {boolean} [isError] - Indicates if there is an error with the input.
 * @property {string} [errorMessage] - The error message to display.
 * @property {string[]} [validator] - Array of validation rules for the input.
 * @property {Function} [onEnter] - Callback function triggered on Enter key press.
 * @property {FocusEventHandler} [onFocus] - Callback function triggered on input focus.
 * @property {boolean} [isReadOnly] - Indicates if the input is read-only.
 * @property {boolean} [autofocus] - Indicates if the input should be focused on page load.
 */
export type SearchInputType = {
    id: string;
    name: string;
    placeholder?: string;
    value: string;
    label?: string;
    className?: string;
    type?: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onChange?: Function;
    isError?: boolean;
    errorMessage?: string;
    validator?: string[];
    onEnter?: Function;
    onFocus?: FocusEventHandler;
    isReadOnly?: boolean;
    autofocus?: boolean;
};

/**
 * Input component for rendering a text or phone input field with validation.
 *
 * @param {SearchInputType} props - The props for the component.
 * @returns {JSX.Element} The rendered Input component.
 */
const Input = ({
    id,
    name,
    placeholder,
    value,
    label,
    onChange = () => null,
    className,
    type = "text",
    onEnter = () => null,
    onFocus = () => null,
    isError,
    errorMessage,
    validator = [],
    isReadOnly = false,
    autofocus = false,
}: SearchInputType) => {
    const handleOnChange = (e: any) => {
        const value = e.target.value;
        const isValid = validate(value, validator);
        onChange(id, value, isValid);
    };
    const keyPressHandler = (e: any) => {
        if (e.keyCode === 13) {
            // "Enter" key pressed
            onEnter();
        }
    };

    return (
        <div className={`w-full text-start ${className}`}>
            {label && <div className="font-medium mb-5 text-lg max-2xl:mb-2 max-2xl:text-base">{label}</div>}
            <div>
                <span className="w-full p-input-icon-left">
                    {type === "phone" ? (
                        <PhoneInput
                            country={"us"}
                            onlyCountries={["us"]}
                            value={value}
                            onChange={(value) => {
                                handleOnChange({ target: { value: value } });
                            }}
                            inputProps={{
                                name: name,
                                required: true,
                                className: `w-full text-base font-normal p-4 py-3 rounded-md bg-gray-100 border border-primaryBorderColor focus:border-primaryBorderColor ${className}`,
                            }}
                            disableDropdown
                            countryCodeEditable={false}
                            containerClass={className}
                            onFocus={onFocus}
                            placeholder={placeholder}
                            alwaysDefaultMask
                            defaultMask="... ......."
                        />
                    ) : (
                        <InputText
                            className={`w-full text-base font-normal p-4 py-3 rounded-md bg-gray-100 border border-primaryBorderColor focus:border-primaryBorderColor ${className} ${
                                isReadOnly && "bg-[#F3F4F6] text-secondaryBlack opacity-70"
                            }`}
                            id={id}
                            type={type}
                            name={name}
                            placeholder={placeholder}
                            onChange={handleOnChange}
                            value={value}
                            onKeyUp={keyPressHandler}
                            onFocus={onFocus}
                            readOnly={isReadOnly}
                            autoFocus={autofocus}
                        />
                    )}
                </span>
            </div>
            {isError && errorMessage && <div className="text-errorMessageColor text-left text-sm mb-2 mt-2">{errorMessage}</div>}
        </div>
    );
};

export default Input;
