import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../utilities/apiService";
import RoleManagementType from "../types/roleManagement";
import { ADMIN_GET_ALL_CUSTOMER_EXECUTIVE, MANAGER_GET_ALL_CUSTOMER_EXECUTIVE } from "../../constants/apiPaths";

const initialState: RoleManagementType = {
    customerSupportExecutive: null,
    isLoading: false,
    error: null,
};

export const fetchCustomerExec = createAsyncThunk("fetchCustomerExec", async (data: any) => {
    const role = data?.roleId || "3";
    const agent = data?.agentId || "3";
    const url =
        `${agent === 1 ? ADMIN_GET_ALL_CUSTOMER_EXECUTIVE : MANAGER_GET_ALL_CUSTOMER_EXECUTIVE}?pageNo=${data.pageNo}&size=${data.size}&roleId=${role}` +
        (data?.searchText ? `&searchText=${data.searchText}` : "");

    return await apiService(url);
});

const roleManagementSlice = createSlice({
    name: "roles",
    initialState,
    reducers: {
        resetCSE: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCustomerExec.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchCustomerExec.fulfilled, (state, action) => {
            state.isLoading = false;
            if (action.payload?.success) {
                state.customerSupportExecutive = action.payload?.data;
                state.error = null;
            } else {
                state.error = action.payload?.data || "Something went wrong! Please try again.";
            }
        });
        builder.addCase(fetchCustomerExec.rejected, (state) => {
            state.isLoading = false;
            state.error = "Something went wrong! Please try again.";
        });
    },
});

export const { resetCSE } = roleManagementSlice.actions;
export default roleManagementSlice.reducer;
