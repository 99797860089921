import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import customerAccountReducer from "./slices/customerAccountSlice";
import { persistReducer, Storage } from "redux-persist";
import faqReducer from "./slices/faqSlice";
import roleManagementReducer from "./slices/roleManagementSlice";
import searchCustomerReducer from "./slices/searchCustomerSlice";
import metadataReducer from "./slices/metadataSlice";
import callActivtiyReducer from "./slices/callActivtiySlice";
import timezoneDropdownReducer from "./slices/timezoneDropdownSlice";
import venuCustomerListReducer from "./slices/venuCustomerListSlice";
import customerAgentReducer from "./slices/customerAgentSlice";
import callHistoryReducer from "./slices/callHistorySlice";
import tncListReducer from "./slices/tncListSlice";
import draggableModalReducer from "./slices/draggableModalSlice";
import stepperModalReducer from "./slices/stepperSlice";
import customerProfileReducer from "./slices/customerProfileSlice";
import Cookies from "js-cookie";
import session from "redux-persist/lib/storage/session";
import caseCommentHistoryReducer from "./slices/caseCommentHistorySlice";
const cookieStorage: Storage = {
    getItem: (key) => {
        return Promise.resolve(Cookies.get(key) || null);
    },
    setItem: (key, value) => {
        Cookies.set(key, value, { secure: true, sameSite: "strict" });
        return Promise.resolve();
    },
    removeItem: (key) => {
        Cookies.remove(key);
        return Promise.resolve();
    },
};

const userConfig = {
    key: "user",
    storage: cookieStorage,
};

const metadataConfig = {
    key: "metadata",
    storage: session,
};

const timezoneDropdownConfig = {
    key: "timezone",
    storage: cookieStorage,
};

const rootReducer = combineReducers({
    user: persistReducer(userConfig, userReducer),
    searchCustomer: searchCustomerReducer,
    customerAccount: customerAccountReducer,
    customerAgentAccount: customerAgentReducer,
    faq: faqReducer,
    roles: roleManagementReducer,
    metadata: persistReducer(metadataConfig, metadataReducer),
    callCases: callActivtiyReducer,
    timezone: persistReducer(timezoneDropdownConfig, timezoneDropdownReducer),
    venuCustomerList: venuCustomerListReducer,
    callHistory: callHistoryReducer,
    tncList: tncListReducer,
    draggableModal: draggableModalReducer,
    stepperModal: stepperModalReducer,
    customerProfile: customerProfileReducer,
    caseCommentHistory: caseCommentHistoryReducer,
});

export default rootReducer;
