/* eslint-disable @typescript-eslint/ban-types */
import { Fragment, useEffect } from "react";
import ReactHotkeys from "react-hot-keys";

/**
 * Type definition for the MessageModal component props.
 */
export type MessageModal = {
  /** Indicates whether the modal should be shown. */
  show: boolean;
  /** Title of the modal. */
  title?: string;
  /** Message to display in the modal. */
  message?: string;
  /** Indicates if the modal represents a success message. */
  isSuccess?: boolean;
  /** Function to set the modal visibility. */
  setShowModal: Function;
  /** Function to execute when the first button is clicked. */
  onClickFirstButton?: Function;
  /** Function to execute when the second button is clicked. */
  onClickSecondButton?: Function;
  /** Text for the first button. */
  firstButtonText?: string;
  /** Text for the third button. */
  thirdButtonText?: string;
  /** Text or node for the second button. */
  secondButtonText?: string | React.ReactNode;
  /** Indicates if the second button action is a success action. */
  secondButtonSuccess?: boolean;
  /** Indicates if the second button should wait for an API response. */
  waitForApi?: boolean;
  /** Function to execute when the modal is closed. */
  onClose?: Function;
  isLoaderSecondButton?: boolean;
};

/**
 * MessageModal component displays a modal with a message and action buttons.
 *
 * @param {MessageModal} props - The props for the MessageModal component.
 * @returns {JSX.Element} The rendered MessageModal component.
 */
const MessageModal = ({
  show,
  title = "Success!",
  message,
  isSuccess = true,
  setShowModal,
  onClickFirstButton = () => null,
  onClickSecondButton = () => null,
  firstButtonText = "ok",
  thirdButtonText = "Cancel",
  secondButtonText,
  secondButtonSuccess,
  waitForApi,
  onClose = () => null,
  isLoaderSecondButton,
}: MessageModal) => {
  useEffect(() => {
    setShowModal(show);
  }, [show]);

  function closeModal() {
    setShowModal(false);
    onClose();
  }

  if (!show) {
    return <div></div>;
  }

  const firstButtonClickHandler = () => {
    closeModal();
    onClickFirstButton();
  };

  const secondButtonClickHandlerWithApi = async () => {
    try {
      await onClickSecondButton();
      closeModal();
    } catch (error) {
      // Handle error if needed
    }
  };

  const secondButtonClickHandlerWithoutApi = () => {
    closeModal();
    onClickSecondButton();
  };

  // Determine which handler to use
  const secondButtonClickHandler = waitForApi
    ? secondButtonClickHandlerWithApi
    : secondButtonClickHandlerWithoutApi;

  return (
    <Fragment>
      <ReactHotkeys keyName="esc" onKeyDown={closeModal} />
      <button
        className="z-[2000] fixed inset-0 bg-black opacity-30"
        onClick={closeModal}
        data-testid="backdrop"
      ></button>
      <div className="z-[2000] fixed m-auto text-center top-1/2 -translate-y-1/2 inset-x-0 w-[45%] bg-white rounded-2xl">
        <div className="shadow-modal text-center py-10  m-auto text-black relative rounded-2xl">
          <div className="flex justify-between">
            <div
              className={`text-4.5xl ml-10 whitespace-nowrap font-extrabold w-full max-2xl:text-3xl ${
                isSuccess ? "text-secondaryTextColor" : "text-errorMessageColor"
              } `}
            >
              {title}
            </div>
            <button
              className="mr-10"
              onClick={closeModal}
              data-testid="crossIcon"
            >
              <span className="pi pi-times text-primaryTextColor text-lg/none max-2xl:text-xs"></span>
            </button>
          </div>
          <p className="text-center text-lg text-trashBinColor whitespace-pre my-8 max-2xl:text-base text-balance">
            {message}
          </p>
          <div className="w-[63%] m-auto">
            {!secondButtonText && (
              <div className="flex justify-center">
                <button
                  className="bg-buttonBgColor p-4 rounded-full w-3/5 text-white font-medium max-2xl:py-2.5"
                  onClick={firstButtonClickHandler}
                >
                  {firstButtonText}
                </button>
              </div>
            )}
            {secondButtonText && (
              <div className="flex justify-center">
                <button
                  className="py-4 rounded-full w-full font-medium bg-messageModalButtonColor mr-6 max-2xl:py-2.5"
                  onClick={firstButtonClickHandler}
                >
                  {thirdButtonText}
                </button>
                <button
                  className={`py-4 rounded-full w-full text-white font-medium max-2xl:py-2.5 ${
                    secondButtonSuccess
                      ? "bg-buttonBgColor"
                      : " bg-errorMessageColor"
                  } `}
                  onClick={secondButtonClickHandler}
                  data-testid="secondTextButton"
                  disabled={isLoaderSecondButton}
                >
                  {secondButtonText}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MessageModal;
