import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { Steps } from 'primereact/steps';
import AgentFeedbackModal from '../../Dashboard/CustomerAccountDetails/CustomerDetails/AgentFeedbackModal/AgentFeedbackModal';
import CallSummaryModal from '../../Dashboard/CustomerAccountDetails/CustomerDetails/CallSummaryModal/CallSummary';
import { useLocation } from 'react-router-dom';
import { setShowModal as setShowDraggableModal } from '../../../redux/slices/draggableModalSlice';
import { 
    setCallId,
    setShowOnlyAgentFeedback,
    setIsMessage,
    setMessage,
    setIsSuccess,
    setIsSuccessText,
    setShowSelectCaseModal,
    setShowCallSummaryModal,
    
} from '../../../redux/slices/stepperSlice';
import { MenuItem } from 'primereact/menuitem';

/**
 * Stepper component to manage and display steps in a process,
 * including call summary and agent feedback modals.
 *
 * @returns {JSX.Element} The rendered Stepper component.
 */
const Stepper = () => {
    const dispatch = useDispatch();
    const {
        callId,
        showOnlyAgentFeedback,
        showOnlyCallSummary,
        verified,
        caseModalValue,

    } = useSelector((state: RootState) => state.stepperModal);


    const [activeIndex, setActiveIndex] = useState<number>(1);
    const [completedSteps, setCompletedSteps] = useState<number[]>([]);

    const headings = ["Case summary", "Agent interaction feedback"];

    /**
     * Increments the active step and records the completed step.
     */
    const stepIncrement = () => {
        setCompletedSteps([...completedSteps, activeIndex]);
        setActiveIndex(activeIndex + 1);
    };

    const location = useLocation();
    const currPath = location.pathname;
    const isInCustomerDetails = currPath.includes('dashboard/customerDetails');

    /**
     * Renders a step item in the Stepper component.
     *
     * @param {MenuItem} item - The step item to render.
     * @param {number} itemIndex - The index of the step item.
     * @returns {JSX.Element} The rendered step item.
     */
    const itemRenderer = (item: MenuItem, itemIndex: number) => {
        const isActiveItem = activeIndex === itemIndex;
        return (
            <span
                className={`inline-flex justify-center items-center border-2 ${completedSteps.includes(itemIndex) && "!border-buttonBgColor"}  rounded-full h-8 w-8 z-10 ${
                    isActiveItem ? "text-black" : "text-stepsInactive"
                } ${completedSteps.includes(itemIndex) ? "bg-buttonBgColor !text-white" : "bg-white"} ${isActiveItem ? "border-buttonBgColor" : "border-stepsInactive"} `}
            >
                {completedSteps.includes(itemIndex) ? <i className={`${item.icon} text-xl`} /> : <p>{itemIndex}</p>}
            </span>
        );
    };

    const items: MenuItem[] = [
        {
            icon: "pi pi-check",
            template: (item) => itemRenderer(item, 1),
        },
        {
            icon: "pi pi-check",
            template: (item) => itemRenderer(item, 2),
        },
    ];

    useEffect(() => {
        if (showOnlyAgentFeedback) {
            setCompletedSteps([1]);
            setActiveIndex(activeIndex + 1);
        }
    }, [showOnlyAgentFeedback]);

    
    /**
     * Handles the action to go back to the previous step.
     */
    const goBack = () => {

        dispatch(setShowCallSummaryModal(false))
        dispatch(setShowDraggableModal(false));
        dispatch(setShowSelectCaseModal(true));
     
    };

    return (
        <div className="card">
            <div className="flex flex-nowrap items-center -mt-4">
                {activeIndex === 1 && isInCustomerDetails && (
                    <button onClick={goBack} className="ml-6 relative z-50" aria-label="back-to-dashboard">
                        <span className={`pi pi-arrow-left bg-white p-3 rounded text-primaryTextColor text-xl max-2xl:text-base `}></span>
                    </button>
                )}
                <div className="text-secondaryTextColor font-extrabold w-full text-5xl max-2xl:text-4xl -ml-6">{headings[activeIndex - 1]}</div>
            </div>
            {!showOnlyCallSummary && <Steps model={items} activeIndex={activeIndex - 1} readOnly={false} className="m-2 pt-4" />}
            {activeIndex === 1 && (
                <CallSummaryModal
                    showOnlyCallSummary={showOnlyCallSummary}
                    verified={verified}
                    setIsMessage={(value: boolean) => dispatch(setIsMessage(value))}
                    setMessage={(value: string) => dispatch(setMessage(value))}
                    setIsSuccess={(value: boolean) => dispatch(setIsSuccess(value))}
                    setIsSuccessText={(value: string) => dispatch(setIsSuccessText(value))}
                    setShowModal={(value: boolean) => dispatch(setShowDraggableModal(value))}
                    showNextStep={stepIncrement}
                    setCallId={(value: any) => dispatch(setCallId(value))}
                    caseModalValue={caseModalValue}
                />
            )}
            {activeIndex === 2 && (
                <AgentFeedbackModal
                    callId={callId}
                    setShowOnlyAgentFeedback={(value: boolean) => dispatch(setShowOnlyAgentFeedback(value))}
                    showOnlyAgentFeedback={showOnlyAgentFeedback}
                    setIsMessage={(value: boolean) => dispatch(setIsMessage(value))}
                    setMessage={(value: string) => dispatch(setMessage(value))}
                    setIsSuccess={(value: boolean) => dispatch(setIsSuccess(value))}
                    setIsSuccessText={(value: string) => dispatch(setIsSuccessText(value))}
                    setShowModal={(value: boolean) => dispatch(setShowDraggableModal(value))}
                />
            )}
        </div>
    );
};

export default Stepper;
