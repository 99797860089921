const decodeJwt = (token: any) => {
    if (token === null) {
        return;
    }
    try {
        const base64Url = token.split(".")[1];
        const base64 = base64Url?.replace(/-/g, "+").replace(/_/g, "/");
        const decodedToken = JSON.parse(atob(base64));
        return decodedToken;
    } catch (error) {
        return null; // Handle the error gracefully by returning null
    }
};

export default decodeJwt;
