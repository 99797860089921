import { useLocation } from "react-router-dom";
import useHttp from "../../hooks/useHttp";
import Header from "../Header/Header";
import { useEffect, useState } from "react";
import { VERIFY_USER_POST_INVITE } from "../../constants/apiPaths";

/**
 * VerifyInvitedEmail component handles the email verification process for invited users.
 * It retrieves the token from the URL, sends a verification request, and displays the result.
 *
 * @returns {JSX.Element} The rendered component.
 */
const VerifyInvitedEmail = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    const { isLoading, apiService } = useHttp();
    const [isSuccess, setIsSuccess] = useState(false);
    const [message, setMessage] = useState("Your email verification is unsuccessful!");

    useEffect(() => {
        /**
         * Sends a verification request to the server using the provided token.
         * Updates the success state and message based on the server response.
         */
        const sendVerificationRequest = async () => {
            try {
                const body = {
                    token: token,
                };
                const response = await apiService(`${VERIFY_USER_POST_INVITE}`, {}, "POST", body);
                setMessage(response.data);
                setIsSuccess(true);
            } catch (e) {
                setIsSuccess(false);
            }
        };

        sendVerificationRequest();
    }, []);

    return (
        <div className="bg-disabledButtonBgColor w-full h-[100vh]">
            <Header />
            <div>
                <div className="w-min m-auto absolute inset-x-0 top-1/2 -translate-y-1/2 text-center p-16 px-40 max-md:w-10/12 max-md:py-8 max-sm:w-11/12 max-sm:py-8 shadow-none rounded-3xl bg-white whitespace-nowrap">
                    <div className={`font-bold text-3xl ${isSuccess ? "text-secondaryTextColor" : "text-errorMessageColor"}`}>
                        {isLoading ? "Processing your email" : message}
                    </div>
                    {!isLoading && isSuccess && (
                        <div className="my-16 max-sm:my-4">
                            <a href="/login" className="border p-16 py-4 rounded-full border-primaryBorderColor font-medium">
                                Continue to login
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VerifyInvitedEmail;
