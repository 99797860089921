import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../utilities/apiService";
import CallHistoryTableType from "../types/callHistory";
import { GET_CALL_HISTORY, GET_DEREGISTERED_USER_CALL_HISTORY } from "../../constants/apiPaths";

const initialState: CallHistoryTableType = {
    callHistoryActivities: null,
    isLoading: false,
    error: null,
    totalRecords: 0,
};

export const fetchCallHistory = createAsyncThunk("fetchCallHistory", async (data: any) => {
    if (data.isDeregisteredUser) {
        return await apiService(`${GET_DEREGISTERED_USER_CALL_HISTORY}?pageNo=${data.pageNo}&pageSize=${data.size}&callId=${data.callId}`);
    }
    return await apiService(`${GET_CALL_HISTORY}?pageNo=${data.pageNo}&pageSize=${data.size}&callId=${data.callId}`);
});

const callHistorySlice = createSlice({
    name: "callHistory",
    initialState,
    reducers: {
        resetHistory: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCallHistory.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchCallHistory.fulfilled, (state, action) => {
            state.isLoading = false;
            state.callHistoryActivities = action.payload?.data?.content;
            state.error = action.payload?.errors || action.payload?.error;
            state.totalRecords = action.payload?.data?.totalElements;
        });
        builder.addCase(fetchCallHistory.rejected, (state) => {
            state.isLoading = false;
            state.error = "Something went wrong! Please try again.";
            state.totalRecords = 0;
        });
    },
});

export const { resetHistory } = callHistorySlice.actions;
export default callHistorySlice.reducer;
