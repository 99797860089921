/* eslint-disable @typescript-eslint/ban-types */
import { useEffect } from "react";
import Input from "../../../../shared/Input/Input";
import useForm from "../../../../../hooks/useForm";
import RadioInput from "../../../../shared/RadioInput/RadioInput";
import { FeedbackQuestionArray } from "../../../../../constants/AgentFeedbackModalConstant";
import RadioInputWithReason, {
  ReasonDescription,
} from "../../../../shared/RadioInputWithReason/RadioInputWithReason";
import RatingInput from "../../../../shared/RatingInput/RatingInput";
import { ADD_FEEDBACK, SUBMIT_DEREGISTERED_FEEDBACK } from "../../../../../constants/apiPaths";
import useHttp from "../../../../../hooks/useHttp";
import SpinningLoader from "../../../../shared/Loader/SpinningLoader";
import { AppDispatch, RootState } from "../../../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { CHECK_STATUS, DEFAULT_PAGE_LIMIT } from "../../../../../constants/stringConstant";
import { fetchUserCallSummary } from "../../../../../redux/slices/customerAccountSlice";
/** Initial values for the feedback form */
const INITIAL_FORM_VALUE = {
  followInstructions: "",
  resolveCustomerIssueAbility: "",
  callExperience: "",
  challengeFacedResolvingIssue: "",
  challengeFacedDescription: "",
  ratingExperience: "",
};

/** Initial error states for the feedback form */
const INITIAL_FORM_ERROR = {
  followInstructions: true,
  resolveCustomerIssueAbility: true,
  callExperience: true,
  challengeFacedResolvingIssue: true,
  challengeFacedDescription: false,
  ratingExperience: true,
};

/**
 * Props for the AgentFeedbackModal component.
 *
 * @param props - Object containing the following properties:
 *  - setIsMessage: Function to set message visibility.
 *  - setMessage: Function to set the message content.
 *  - setIsSuccess: Function to set success state.
 *  - setIsSuccessText: Function to set success message text.
 *  - setShowOnlyAgentFeedback: Function to control visibility of agent feedback.
 *  - showOnlyAgentFeedback: Boolean indicating if only agent feedback should be shown.
 *  - callId: Identifier for the call being evaluated.
 *  - setShowModal: Function to control modal visibility.
 * @returns {JSX.Element} A JSX element representing the AgentFeedbackModal component.
 */
const AgentFeedbackModal = ({
  setIsMessage,
  setIsSuccess,
  setIsSuccessText,
  setMessage,
  setShowModal,
  setShowOnlyAgentFeedback,
  showOnlyAgentFeedback,
  callId,
}: {
  setIsMessage: Function;
  setMessage: Function;
  setIsSuccess: Function;
  setIsSuccessText: Function;
  setShowOnlyAgentFeedback: Function;
  showOnlyAgentFeedback: boolean;
  callId: any;
  setShowModal: Function;
}) => {
  const {
    formValues,
    formErrors,
    showFormErrors,
    setShowFormErrors,
    onRadioChange,
    onTextChange,
    onRatingChange,
    updateFormError
  } = useForm(INITIAL_FORM_VALUE, INITIAL_FORM_ERROR);
  const reasonDescription: ReasonDescription = {
    label: "",
    id: "challengeFacedDescription",
    name: "challengeFacedDescription",
    value: formValues.chaallengFacedDescription,
    placeHolder: "Please specify",
    onTextChange: onTextChange,
    isError: false,
    errorMessage: "Please specify",
    showFormErrors,
    formErrors,
  };
  const { isLoading, apiService } = useHttp();
  const { currentCustomerDetails } = useSelector((state: RootState) => state.customerProfile);
  const dispatch = useDispatch<AppDispatch>();
    /**
     * Handles the form submission for feedback.
     *
     * @returns {Promise<void>} - A promise that resolves when the submission is complete.
     */
  const handleSubmit = async () => {
    setShowFormErrors(true);
    if (!Object.values(formErrors).some((error) => error)) {
      if (showOnlyAgentFeedback) {
        setShowOnlyAgentFeedback(false);
      }
  
      const payload = [
        {
          id: 1,
          question: FeedbackQuestionArray[0].question,
          ans: [
            {
              type: "radiobuttion",
              value: formValues.followInstructions,
            },
          ],
        },
        {
          id: 2,
          question: FeedbackQuestionArray[1].question,
          ans: [
            {
              type: "radiobuttion",
              value: formValues.resolveCustomerIssueAbility,
            },
          ],
        },
        {
          id: 3,
          question: FeedbackQuestionArray[2].question,
          ans: [
            {
              type: "radiobuttion",
              value: formValues.challengeFacedResolvingIssue,
              subresponses: [
                {
                  type: "text",
                  value:
                    formValues.challengeFacedResolvingIssue === "Yes"
                      ? formValues.challengeFacedDescription
                      : "",
                },
              ],
            },
          ],
        },
        {
          id: 4,
          question: "What could have made the call experience better?",
          ans: [
            {
              type: "text",
              value: formValues.callExperience,
            },
          ],
        },
        {
          id: 5,
          question: "Overall rating of the interaction",
          ans: [
            {
              type: "rating",
              value: formValues.ratingExperience,
            },
          ],
        },
      ];
      const body = {
        callSummaryId: callId,
        data: payload,
      };
      try {
        if (currentCustomerDetails?.status === CHECK_STATUS) { 
          await apiService(`${SUBMIT_DEREGISTERED_FEEDBACK}`, {}, "POST", body);
        }
        else {
          await apiService(`${ADD_FEEDBACK}`, {}, "POST", body);
        }
      setIsSuccess(true);
      setIsSuccessText("Agent feedback submitted");
        setShowModal(false);
        dispatch(fetchUserCallSummary({ page: 0, rows: DEFAULT_PAGE_LIMIT, userId: currentCustomerDetails?.userId, isDeregisteredUser: currentCustomerDetails?.status === CHECK_STATUS }));
      } catch (error:any) {
        setIsMessage(true);
        setMessage(error?.message??"Something went wrong. Please try again ",'error');
      }
      
    }
  };
  useEffect(()=>{
    const isValid=formValues.challengeFacedResolvingIssue==="Yes";
    updateFormError("challengeFacedDescription",isValid);
  }, [formValues.challengeFacedResolvingIssue])
     /**
     * Renders the AgentFeedbackModal component.
     *
     * @returns {JSX.Element} - The rendered component.
     */
  return (
    <div className="mt-8 2xl:mt-12 w-3/4 2xl:w-3/5 text-center m-auto ">
      <div className="mb-8 max-2xl:mb-4">
        <RadioInput
          id="followInstructions"
          name="followInstructions"
          value={formValues.followInstructions}
          label={FeedbackQuestionArray[0].question}
          options={FeedbackQuestionArray[0].options}
          onChange={onRadioChange}
          isError={formErrors.followInstructions && showFormErrors}
          errorMessage="Please select at least one of the following option"
        />

      </div>
      <div className="mb-8 max-2xl:mb-4">
        <RadioInput
          id="resolveCustomerIssueAbility"
          name="resolveCustomerIssueAbility"
          value={formValues.resolveCustomerIssueAbility}
          label={FeedbackQuestionArray[1].question}
          options={FeedbackQuestionArray[1].options}
          onChange={onRadioChange}
          isError={formErrors.resolveCustomerIssueAbility && showFormErrors}
          errorMessage="Please select at least one of the following option"
        />
      </div>
      <div className="mb-8 max-2xl:mb-4">
        <RadioInputWithReason
          answerReason={true}
          id="challengeFacedResolvingIssue"
          name="challengeFacedResolvingIssue"
          value={formValues.challengeFacedResolvingIssue}
          label={FeedbackQuestionArray[2].question}
          options={FeedbackQuestionArray[2].options}
          onChange={onRadioChange}
          isError={formErrors.challengeFacedResolvingIssue && showFormErrors}
          errorMessage="Please select at least one of the following option"
          reasonDescription={reasonDescription}
        />
      </div>
      <div className="mb-8 max-2xl:mb-4">
        <Input
          label="What could have made the call experience better?"
          id="callExperience"
          name="callExperience"
          placeholder="Please specify"
          value={formValues.callExperience}
          onChange={onTextChange}
          className={
            formErrors.callExperience && showFormErrors ? "p-invalid" : ""
          }
          isError={formErrors.callExperience && showFormErrors}
          errorMessage="Please provide the call reason"
        />
      </div>
      <div className="card flex justify-content-center">
        <RatingInput
          label="Overall rating of the interaction"
          id="ratingExperience"
          name="ratingExperience"
          value={formValues.ratingExperience}
          onChange={onRatingChange}
          isError={formErrors.ratingExperience && showFormErrors}
          errorMessage="Please rate the overall interaction"
        />
      </div>
      <div className="w-full text-center mt-12 max-2xl:mt-8">
        <button
          className={`py-4 w-3/5 rounded-full font-semibold whitespace-nowrap mx-auto bg-buttonBgColor text-white max-2xl:py-3`}
          onClick={handleSubmit}
          disabled={isLoading}
          aria-label="submitCallSummary"
        >
          {isLoading ? <SpinningLoader /> : <>Submit</>}
        </button>
      </div>
    </div>
  );
};

export default AgentFeedbackModal;