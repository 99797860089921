// src/redux/slices/stepperSlice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface StepperState { // Ensure this is exported
    callId: any;
    showOnlyAgentFeedback: boolean;
    showOnlyCallSummary: boolean;
    verified: boolean;
    isMessage: boolean;
    message: string;
    isSuccess: boolean;
    isSuccessText: string;
    caseModalValue: string;
    showSelectStepperModal: boolean;
    showCallSummaryModal:boolean;
    showSelectCaseModal:boolean;
}

const initialState: StepperState = {
    callId: null,
    showOnlyAgentFeedback: false,
    showOnlyCallSummary: false,
    verified: false,
    isMessage: false,
    message: '',
    isSuccess: false,
    isSuccessText: '',
    caseModalValue: '',
    showSelectStepperModal: false,
    showCallSummaryModal:false,
    showSelectCaseModal:false
};

const stepperSlice = createSlice({
    name: 'stepperModal',
    initialState,
    reducers: {
        setCallId(state, action: PayloadAction<any>) {
            state.callId = action.payload;
        },
        setShowOnlyAgentFeedback(state, action: PayloadAction<boolean>) {
            state.showOnlyAgentFeedback = action.payload;
        },
        setShowOnlyCallSummary(state, action: PayloadAction<boolean>) {
            state.showOnlyCallSummary = action.payload;
        },
        setVerified(state, action: PayloadAction<boolean>) {
            state.verified = action.payload;
        },
        setIsMessage(state, action: PayloadAction<boolean>) {
            state.isMessage = action.payload;
        },
        setMessage(state, action: PayloadAction<string>) {
            state.message = action.payload;
        },
        setIsSuccess(state, action: PayloadAction<boolean>) {
            state.isSuccess = action.payload;
        },
        setShowCallSummaryModal(state, action: PayloadAction<boolean>) {
            state.showCallSummaryModal = action.payload;
        },
        setIsSuccessText(state, action: PayloadAction<string>) {
            state.isSuccessText = action.payload;
        },
        setCaseModalValue(state, action: PayloadAction<string>) {
            state.caseModalValue = action.payload;
        },
        setShowSelectStepperModal(state, action: PayloadAction<boolean>) {
            state.showSelectStepperModal = action.payload;
        },
        setShowSelectCaseModal(state,action:PayloadAction<boolean>)
        {
            state.showSelectCaseModal=action.payload;
        },

        resetStepperState(state) {
            state.callId = null;
            state.showOnlyAgentFeedback = false;
            state.showOnlyCallSummary = false;
            state.verified = false;
            state.isMessage = false;
            state.message = '';
            state.isSuccess = false;
            state.isSuccessText = '';
            state.caseModalValue = '';
            state.showSelectStepperModal = false;
            state.showCallSummaryModal=false;
            state.showSelectCaseModal=false;
        },
    },
});

export const {
    setCallId,
    setShowOnlyAgentFeedback,
    setShowOnlyCallSummary,
    setVerified,
    setIsMessage,
    setMessage,
    setIsSuccess,
    setIsSuccessText,
    setCaseModalValue,
    setShowSelectStepperModal,
    resetStepperState,
    setShowCallSummaryModal,
    setShowSelectCaseModal
    
} = stepperSlice.actions;

export default stepperSlice.reducer;
