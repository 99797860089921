import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import { resetFAQ } from "../redux/slices/faqSlice";
import { resetCustomerAccount } from "../redux/slices/customerAccountSlice";
import { googleLookUp } from "../utilities/firebase";
import { TERMS_AND_CONDTITIONS, VERIFY_USER_POST_INVITE } from "../constants/apiPaths";
import refreshTokenEveryApiCall from "../utilities/refreshToken";
import { resetSearchCustomer } from "../redux/slices/searchCustomerSlice";
import Cookies from "js-cookie";
import { clearAllCookies } from "../utilities/resuableColumnFunctions";

/**
 * Custom hook to manage HTTP requests, including API service calls, authentication, error handling, and sign-out functionality.
 *
 * @returns {{
 *   isLoading: boolean,
 *   error: string | null,
 *   apiService: (path: string, headers?: object, method?: string, body?: any, options?: { idToken?: string }) => Promise<any>,
 *   clearError: () => void,
 *   signOut: () => void,
 * }} - An object containing HTTP service methods and states.
 *
 * @example
 * const {
 *   isLoading,
 *   error,
 *   apiService,
 *   clearError,
 *   signOut,
 * } = useHttp();
 *
 * // Example: Making an API call
 * apiService('/api/data', { 'Custom-Header': 'value' }, 'POST', { key: 'value' })
 *   .then(data => console.log(data))
 *   .catch(err => console.error(err));
 */

const useHttp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { idToken, refreshToken } = useSelector((state: any) => state?.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const FREE_PATHS = [VERIFY_USER_POST_INVITE, TERMS_AND_CONDTITIONS];

    const apiService = useCallback(async (path: string, headers?: object, method?: string, body?: any, options?: { idToken?: string }) => {
        setIsLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + path;
        try {
            let headerPayload: any = {
                "Content-Type": "application/json",
            };

            if (!FREE_PATHS.includes(path.split("?")[0])) {
                await googleLookUp(idToken || options?.idToken, signOut);
            }

            // get Auth token from redux or storage
            if (idToken) {
                headerPayload = {
                    ...headerPayload,
                    Authorization: "Bearer " + idToken,
                };
            }

            headerPayload = {
                ...headerPayload,
                ...headers,
            };

            const response = await fetch(url, {
                method: method ?? "GET",
                headers: headerPayload,
                body: body ? JSON.stringify(body) : null,
                credentials: "omit",
            });

            if (response.status === 401) {
                // Remove the token if its invalid or expired.
                signOut();
                setIsLoading(false);
                return null;
            }
            const storage = JSON.parse(Cookies.get("persist:user") ?? "{}");
            if (Object.keys(storage).length === 0) {
                signOut();
                return null;
            }
            const newToken = await refreshTokenEveryApiCall({ refreshToken: refreshToken });
            if (newToken.id_token) {
                const updatedIdToken = `"${newToken.id_token}"`;
                const updatedStorage = {
                    ...storage,
                    idToken: updatedIdToken,
                };
                Cookies.set("persist:user", JSON.stringify(updatedStorage), {
                    secure: true,
                    sameSite: "strict",
                });
            }
            const data = await response.json();
            setIsLoading(false);

            if (!response.ok) {
                throw new Error(data.message || data.error || data.errors || "Something went wrong!");
            }

            return data;
        } catch (e: any) {
            // Handle errors
            setIsLoading(false);
            setError(e?.message);
            throw e;
        }
    }, []);

    const clearError = useCallback(() => {
        setError(null);
    }, []);

    const signOut = useCallback(() => {
        dispatch(resetSearchCustomer());
        dispatch(logoutUser());
        dispatch(resetFAQ());
        dispatch(resetCustomerAccount());
        clearAllCookies();
        sessionStorage.clear();
        navigate("/login");
    }, []);

    return {
        isLoading,
        error,
        apiService,
        clearError,
    };
};

export default useHttp;
