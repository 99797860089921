import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { DEFAULT_TIMEZONE_VALUE } from "../constants/stringConstant";
import { dateFormatter } from "../components/shared/DateFormat/FormatDate";
import Cookies from "js-cookie";

/**
 * Formats the date and time based on the preferred timezone.
 *
 * @param {number} time - The time in seconds since the Unix epoch to be formatted.
 * @returns {JSX.Element} - A JSX element containing the formatted date and time with line breaks.
 */
export const formatDateBody = (time: number) => {
    const { preferedTimezoneValue } = useSelector((state: RootState) => state.timezone);
    const timezone = preferedTimezoneValue && preferedTimezoneValue.trim() !== "" ? preferedTimezoneValue : DEFAULT_TIMEZONE_VALUE;
    const formattedDate = dateFormatter(time * 1000, timezone);
    const [datePart, timePart] = formattedDate.split(" at ");
    return (
        <span>
            {datePart} at
            <br />
            {timePart}
        </span>
    );
};

/**
 * Formats a mobile phone number by adding country code and grouping digits.
 *
 * @param {string} mobilePhone - The mobile phone number to be formatted.
 * @returns {string | null} - A formatted phone number with hyphens or null if the number is not provided.
 */
export const formatMobilePhoneBody = (mobilePhone: string) => {
    if (mobilePhone) {
        return `+${mobilePhone?.slice(0, 1)} ${mobilePhone?.slice(1, 4)}-${mobilePhone.slice(4, 7)}-${mobilePhone?.slice(7)}`;
    }
    return null;
};

/**
 * Compares two objects for deep equality.
 *
 * @param {any} obj1 - The first object to compare.
 * @param {any} obj2 - The second object to compare.
 * @returns {boolean} - True if the objects are equal, false otherwise.
 */
export const isEqual = (obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
};

/**
 * Removes all cookies from the browser.
 */
export const clearAllCookies = () => {
    const allCookies = Cookies.get(); // Get all cookies
    for (const cookie in allCookies) {
        Cookies.remove(cookie, { path: "/" });
    }
};

/**
 * Returns the appropriate CSS class for the balance color.
 *
 * @param {string | number | undefined} balance - The balance value to determine the color class.
 * @returns {string} - A CSS class name for the balance color based on whether it's positive or negative.
 */
export const getBalanceColorClass = (balance: string | number | undefined): string => {
    if (!balance) {
        return ""; // Default color when balance is not available
    }
    const numericBalance = parseFloat(balance.toString().replace(/[$,]/g, "")); // Convert balance to a number
    if (numericBalance >= 0) {
        return "text-buttonBgColor"; // Positive balance
    } else {
        return "text-errorMessageColor"; // Negative balance
    }
};

/**
 * Adds a nonce attribute to all <script> tags in the provided HTML content.
 *
 * This function scans the HTML content and injects a nonce attribute into each <script> tag.
 * This is often used for adding a Content Security Policy (CSP) nonce to scripts, ensuring that
 * only authorized scripts with a matching nonce can execute.
 *
 * @param htmlContent - The HTML content as a string, containing <script> tags where the nonce
 * will be injected.
 * @param nonce - The nonce value to be added to each <script> tag. This should be a unique,
 * securely generated string for each response to enhance security.
 *
 * @returns A new string of HTML content with the nonce attribute added to each <script> tag.
 */
export const injectNonceToScripts = (htmlContent: string, nonce: string) => {
    // This regex looks for <script> tags with or without a nonce attribute.
    // If a nonce is present, it will replace it with the new nonce.
    return htmlContent.replace(/<script\b([^>]*?)(?:\snonce="[^"]*")?(?=\s|>)/g, `<script nonce="${nonce}"$1`);
};

/**
 * Adds a nonce attribute to all <style> tags in the provided HTML content.
 *
 * This function scans the HTML content and injects a nonce attribute into each <style> tag.
 *
 * @param htmlContent - The HTML content as a string, containing <style> tags where the nonce
 * will be injected.
 * @param nonce - The nonce value to be added to each <style> tag. This should be a unique,
 * securely generated string for each response to enhance security.
 *
 * @returns A new string of HTML content with the nonce attribute added to each <style> tag.
 */
export const injectNonceToStyles = (htmlContent: string, nonce: string): string => {
    // This regex looks for <style> tags with or without a nonce attribute.
    // If a nonce is present, it will replace it with the new nonce.
    return htmlContent.replace(/<style\b([^>]*?)(?:\snonce="[^"]*")?(?=\s|>)/g, `<style nonce="${nonce}"$1`);
};

/**
 * Formats an email address by splitting it into two lines if the local part is long.
 *
 * @param {string} email - The email address to format.
 * @returns {JSX.Element | string} - A JSX element with a split email address or "N/A" if the email is empty.
 */
const formatEmailBody = (email: string) => {
    if (email) {
        const [firstPart, remainingPart] = email.split("@");
        if (firstPart.length > 10) {
            return (
                <span>
                    {firstPart}
                    <br />@{remainingPart}
                </span>
            );
        } else {
            return email;
        }
    }
    return "N/A";
};

export default formatEmailBody;
