/* eslint-disable @typescript-eslint/ban-types */
import { RadioButton, RadioButtonChangeEvent } from "primereact/radiobutton";
import { useEffect, useState } from "react";
import Input from "../Input/Input";

/**
 * Type representing a radio button option.
 */
type RadioButtonOption = {
    id: string;
    value: string;
    title: string;
};

/**
 * Type representing the reason description input properties.
 */
export type ReasonDescription = {
    id: string;
    name: string;
    value: string;
    onTextChange: Function;
    isError: boolean;
    label: string;
    placeHolder: string;
    errorMessage: string;
    formErrors: any;
    showFormErrors: any;
};

/**
 * Type representing the props for the RadioInputWithReason component.
 */
type RadioInputTypes = {
    id: string;
    name: string;
    value: string;
    onChange: Function;
    isError: boolean;
    label: string;
    errorMessage: string;
    options: RadioButtonOption[];
    answerReason: boolean;
    reasonDescription: ReasonDescription;
};

/**
 * RadioInputWithReason component renders a set of radio buttons with an optional reason input.
 * @param {RadioInputTypes} props - Object containing the following properties:
 *  - id: The unique identifier for the radio input.
 *  - name: The name attribute for the radio input group.
 *  - value: The current selected value.
 *  - label: The label for the radio input.
 *  - onChange: Function to call when the value changes.
 *  - isError: Boolean indicating if there is an error.
 *  - errorMessage: The error message to display if there is an error.
 *  - options: An array of options to render as radio buttons.
 *  - answerReason: The selected reason (if applicable).
 *  - reasonDescription: Description for the reason (if applicable).
 * @returns {JSX.Element} The rendered radio input with reason component.
 */
const RadioInputWithReason = ({ id, name, value, label, onChange = () => null, isError, errorMessage, options = [], answerReason, reasonDescription }: RadioInputTypes) => {
    const [currentValue, setCurrentValue] = useState(value);
    const handleOnChange = (e: RadioButtonChangeEvent) => {
        onChange(id, e.value);
    };

    useEffect(() => {
        setCurrentValue(value);
    }, [value]);

    return (
        <div className={`w-full text-start mb-4`}>
            {label && <div className="font-medium mb-2 text-base">{label}</div>}
            <div>
                <span className="w-full p-input-icon-left flex flex-col">
                    {options.map((option) => (
                        <div className="flex flex-col justify-start mr-6 py-3" key={option.id}>
                            <div className="flex flex-row">
                                <RadioButton
                                    inputId={option.id}
                                    name={name}
                                    value={option.value}
                                    onChange={(e) => handleOnChange(e)}
                                    pt={{
                                        icon: { className: "bg-primary border border-white" },
                                        box: { className: `border-4 border-[#cbd5e1] bg-white` },
                                    }}
                                    className="mr-2"
                                    checked={currentValue === option.value}
                                />
                                <div className="my-auto">{option.title}</div>
                            </div>
                            <div className="mt-3">
                                {answerReason && option.value === "Yes" && currentValue === option.value && (
                                    <Input
                                        label=""
                                        id={reasonDescription?.id}
                                        name={reasonDescription?.name}
                                        placeholder={reasonDescription?.placeHolder}
                                        value={reasonDescription?.value}
                                        onChange={reasonDescription?.onTextChange}
                                        className={reasonDescription?.formErrors.challengeFacedDescription && reasonDescription?.showFormErrors ? "p-invalid" : ""}
                                        isError={reasonDescription?.formErrors.challengeFacedDescription && reasonDescription?.showFormErrors}
                                        errorMessage="Please specify"
                                    />
                                )}
                            </div>
                        </div>
                    ))}
                </span>
            </div>
            {isError && errorMessage && <div className=" text-errorMessageColor text-left text-sm my-2">{errorMessage}</div>}
        </div>
    );
};

export default RadioInputWithReason;
