import { useState } from "react";

/**
 * Custom hook to manage form state, including form values, form errors, and error display.
 *
 * @param {any} initialFormValues - The initial values for the form fields.
 * @param {any} initialFormErrors - The initial error state for the form fields.
 * @param {string[]} [notRequiredFields] - Array of form field IDs that should not require validation when empty.
 *
 * @returns {{
 *   formValues: any,
 *   formErrors: any,
 *   showFormErrors: boolean,
 *   setShowFormErrors: (value: boolean) => void,
 *   onTextChange: (id: string, value: string, isValid: boolean) => void,
 *   onSelectChange: (id: string, value: any) => void,
 *   onRadioChange: (id: string, value: any) => void,
 *   onRatingChange: (id: string, value: any) => void,
 *   updateFormError: (id: string, value: boolean) => void,
 *   setFormValues: (values: any) => void,
 * }} - An object containing form state management methods.
 *
 * @example
 * const {
 *   formValues,
 *   formErrors,
 *   showFormErrors,
 *   setShowFormErrors,
 *   onTextChange,
 *   onRatingChange,
 *   onSelectChange,
 *   updateFormError,
 *   setFormValues,
 *   onRadioChange,
 * } = useForm(initialValues, initialErrors, notRequiredFields);
 *
 * // Example: Handling text input change
 * onTextChange("name", "John", true);
 */

const useForm = (initialFormValues: any, initialFormErrors: any, notRequiredFields: string[] = []) => {
    const [formValues, setFormValues] = useState(initialFormValues);
    const [formErrors, setFormErrors] = useState(initialFormErrors);
    const [showFormErrors, setShowFormErrors] = useState(false);

    const onTextChange = (id: string, value: string, isValid: boolean) => {
        setFormValues((state: any) => ({ ...state, [id]: value }));
        const shouldValidate = value === "" && !notRequiredFields.includes(id);
        setFormErrors((prevErrors: any) => ({ ...prevErrors, [id]: shouldValidate ? true : !isValid }));
    };

    const onSelectChange = (id: string, value: any) => {
        setFormValues((state: any) => ({ ...state, [id]: value }));
        setFormErrors((prev: any) => ({ ...prev, [id]: false }));
    };
    const onRadioChange = (id: string, value: any) => {
        setFormValues((state: any) => ({ ...state, [id]: value }));
        setFormErrors((prev: any) => ({ ...prev, [id]: false }));
    };
    const onRatingChange = (id: string, value: any) => {
        setFormValues((state: any) => ({ ...state, [id]: value }));
        setFormErrors((prev: any) => ({ ...prev, [id]: false }));
    };
    const updateFormError = (id: string, value: boolean) => {
        setFormErrors((prev: any) => ({ ...prev, [id]: value }));
    };

    return {
        formValues,
        formErrors,
        showFormErrors,
        setShowFormErrors,
        onTextChange,
        onRatingChange,
        onSelectChange,
        updateFormError,
        setFormValues,
        onRadioChange,
    };
};

export default useForm;
