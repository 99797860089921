import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../utilities/apiService";
import { GET_TNCLIST } from "../../constants/apiPaths";
import tncList from "../types/tncList";
const initialState: tncList = {
  currentVersion: "",
  tncDetailsList: [],
  totalRecords: 0,
  tableLoading: false,
  error: null,
};

let controller = new AbortController();
export const fetchTnCPreviousVersions = createAsyncThunk(
  "fetchTnCPreviousVersions",
  async (data: any) => {
    controller.abort(); // Cancel previous request
    controller = new AbortController(); // Create a new abort controller for the current request
    try {
      const { pageNo, size } = data;
      const response = await apiService(
        `${GET_TNCLIST}?page=${pageNo}&size=${size}`,
        {},
        "GET",
        null,
        controller.signal
      );
      return response;
    } catch (err: any) {
      if (err.name === "AbortError") {
        console.log("Request aborted");
      } else {
        throw err;
      }
    }
  }
);

const tncListSlice = createSlice({
  name: "tncList",
  initialState,
  reducers: {
    resettncList: () => initialState,
  },
  extraReducers: (builder) => {
    // Fetch Tnc Previous Version
    builder.addCase(fetchTnCPreviousVersions.pending, (state) => {
      state.tableLoading = true;
      state.error = null;
    });

    builder.addCase(fetchTnCPreviousVersions.fulfilled, (state, action) => {
      state.tableLoading = false;
      state.tncDetailsList = action.payload?.data?.content;
      state.currentVersion = action.payload?.data?.content[0]?.version;
      state.totalRecords = action.payload?.data?.totalElements;
      state.error = action.payload?.errors || action.payload?.error;
    });

    builder.addCase(fetchTnCPreviousVersions.rejected, (state) => {
      state.tncDetailsList = [];
      state.tableLoading = false;
      state.error = "Something went wrong! Please try again.";
    });
  },
});

export const { resettncList } = tncListSlice.actions;
export default tncListSlice.reducer;
