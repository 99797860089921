/* eslint-disable @typescript-eslint/ban-types */ 
import validate from "../../../utilities/validate";
import { InputTextarea } from "primereact/inputtextarea";
import { SearchInputType } from "../Input/Input";

/**
 * InputArea component props, extending the SearchInputType.
 *
 * @extends SearchInputType
 */
const InputArea = ({
    id,
    name,
    placeholder,
    value,
    label,
    onChange = () => null,
    className,
    onEnter = () => null,
    onFocus = () => null,
    isError,
    errorMessage,
    validator = [],
    isReadOnly = false,
    autofocus = false,
}: SearchInputType) => {
    /**
     * Handles changes in the textarea input.
     * 
     * @param {any} e - The change event from the textarea.
     */
    const handleOnChange = (e: any) => {
        const oldValue = e.target.value;
        const isValid = validate(oldValue, validator);
        onChange(id, oldValue, isValid);
    };

    /**
     * Handles key events in the textarea.
     * 
     * @param {any} e - The key event from the textarea.
     */
    const keyHandler = (e: any) => {
        if (e.keyCode === 13) {
            // "Enter" key pressed
            onEnter();
        }
    };

    /**
     * Renders the InputArea component.
     * 
     * @returns {JSX.Element} The rendered component.
     */
    return (
        <div className={`w-full text-start ${className}`}>
            {label && <div className="font-medium mb-5 text-lg max-2xl:mb-2 max-2xl:text-base">{label}</div>}
            <div>
                <span className="w-full p-input-icon-left">
                    <InputTextarea
                        className={`w-full text-base font-normal p-4 py-3 rounded-md bg-gray-100 border resize-none border-primaryBorderColor focus:border-primaryBorderColor ${className} ${
                            isReadOnly && "bg-[#F3F4F6] text-secondaryBlack opacity-70"
                        }`}
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        onChange={handleOnChange}
                        value={value}
                        onKeyUp={keyHandler}
                        onFocus={onFocus}
                        readOnly={isReadOnly}
                        autoFocus={autofocus}
                        autoResize
                    />
                </span>
            </div>
            {isError && errorMessage && <div className="text-errorMessageColor text-left text-sm mb-2 mt-2">{errorMessage}</div>}
        </div>
    );
};

export default InputArea;
