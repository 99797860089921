import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../utilities/apiService";
import { SearchCustomerType } from "../types/customerAccount";
import { SEARCH_CUSTOMERS_BY_SEARCHTEXT } from "../../constants/apiPaths";
const initialState: SearchCustomerType = {
    customers: null,
    isLoading: false,
    error: null,
};
export const fetchCustomers = createAsyncThunk("fetchCustomers", async (data: any) => await apiService(`${SEARCH_CUSTOMERS_BY_SEARCHTEXT}?searchText=${data.searchText}`));

const searchCustomerSlice = createSlice({
    name: "searchCustomer",
    initialState,
    reducers: {
        resetSearchCustomer: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCustomers.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchCustomers.fulfilled, (state, action) => {
            state.isLoading = false;
            state.customers = action.payload?.data;
            state.error = action.payload?.errors || action.payload?.error;
            if (action.payload?.data && action.payload?.data.length === 0) {
                state.error = "No result found";
            }
        });
        builder.addCase(fetchCustomers.rejected, (state) => {
            state.isLoading = false;
            state.error = "Something went wrong! Please try again.";
        });
    },
});

export const { resetSearchCustomer } = searchCustomerSlice.actions;

export default searchCustomerSlice.reducer;
