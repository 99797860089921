import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../utilities/apiService";
import { GET_COMMENT_LIST, GET_DEREGISTERED_USER_COMMENTS } from "../../constants/apiPaths";
import CaseCommentTableType from "../types/caseComment";

const initialState: CaseCommentTableType = {
    commentsHistory: [],
    isLoading: false,
    error: null,
    totalRecords: 0,
};

export const fetchComments = createAsyncThunk("fetchComments", async (data: any) => {
    if (data.isDeregisteredUser) {
        return await apiService(`${GET_DEREGISTERED_USER_COMMENTS}?pageNo=${data.pageNo}&pageSize=${data.size}&callId=${data.callId}`);
    }
    return await apiService(`${GET_COMMENT_LIST}?pageNo=${data.pageNo}&pageSize=${data.size}&callId=${data.callId}`);
});

const caseCommentHistory = createSlice({
    name: "caseCommentHistory",
    initialState,
    reducers: {
        resetCommentHistory: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchComments.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchComments.fulfilled, (state, action) => {
            state.isLoading = false;
            state.commentsHistory = action.payload?.data?.content;
            state.error = action.payload?.errors || action.payload?.error;
            state.totalRecords = action.payload?.data?.totalElements;
        });
        builder.addCase(fetchComments.rejected, (state) => {
            state.isLoading = false;
            state.error = "Something went wrong! Please try again.";
            state.totalRecords = 0;
        });
    },
});

export const { resetCommentHistory } = caseCommentHistory.actions;
export default caseCommentHistory.reducer;
