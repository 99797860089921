import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CustomerAccountDetailsType from "../types/customerAccount";
import apiService from "../../utilities/apiService";
import {
    CALL_SUMMARY,
    END_USER_NOTIFICATIONS,
    GET_DEREGISTERED_USER_ACTIVITY,
    GET_DEREGISTERED_USER_BANK_ACCOUNT_ACTIVITY,
    GET_DEREGISTERED_USER_CALL_SUMMARY,
    GET_DEREGISTERED_USER_NOTIFICATIONS,
    GET_DEREGISTERED_USER_PROFILE,
    GET_DEREGISTERED_USER_STATEMENTS,
    GET_USER_BANK_ACCOUNT_ACTIVITY,
    GET_USER_DETAILS_BY_ID,
    GET_USER_PROFILE_BY_ID,
    STATEMENTS,
} from "../../constants/apiPaths";

const initialState: CustomerAccountDetailsType = {
    currentCustomerDetails: null,
    currentCustomerActivities: [],
    currentCustomerCallSummary: [],
    currentCustomerStatementActivities: [],
    currentCustomerNotifications: [],
    currentCustomerBankAccountActivities: [],
    totalRecords: 0,
    profileLoading: false,
    tableLoading: false,
    error: null,
    loadingCount: 0,
};

export const fetchUserProfile = createAsyncThunk("fetchUserProfile", async (data: any) => {
    if (data.isDeregisteredUser) {
        return await apiService(`${GET_DEREGISTERED_USER_PROFILE}?id=${data.userId}`);
    }
    return await apiService(`${GET_USER_PROFILE_BY_ID}?id=${data.userId}`);
});

let controller = new AbortController();

export const fetchUserActivities = createAsyncThunk("fetchUserActivities", async (data: any) => {
    controller.abort(); // Cancel previous request
    controller = new AbortController(); // Create a new abort controller for the current request
    try {
        const { userId, pageNo, size, activities } = data;
        const activityList = activities.join("%2C");
        if (data.isDeregisteredUser) {
            const response = await apiService(`${GET_DEREGISTERED_USER_ACTIVITY}?id=${userId}&pageNo=${pageNo}&size=${size}&activityType=${activityList}`, {}, "GET", null, controller.signal);
            return response;
        }
        const response = await apiService(`${GET_USER_DETAILS_BY_ID}?id=${userId}&pageNo=${pageNo}&size=${size}&activityType=${activityList}`, {}, "GET", null, controller.signal);
        return response;
    } catch (err: any) {
        if (err.name === "AbortError") {
            console.log("Request aborted");
        } else {
            throw err;
        }
    }
});

export const fetchUserCallSummary = createAsyncThunk("fetchUserCallSummary", async (data: any) => {
    if (data.isDeregisteredUser) {
        return await apiService(`${GET_DEREGISTERED_USER_CALL_SUMMARY}?statusIds=5,6&pageNo=${data.page}&pageSize=${data.rows}&endUserId=${data.userId}`);
    }
    return await apiService(`${CALL_SUMMARY}?statusIds=5,6&pageNo=${data.page}&pageSize=${data.rows}&endUserId=${data.userId}`);
});

export const fetchUserStatement = createAsyncThunk("fetchUserStatement", async (data: any) => {
    if (data.isDeregisteredUser) {
        return await apiService(`${GET_DEREGISTERED_USER_STATEMENTS}?pageNo=${data.page}&pageSize=${data.rows}&userId=${data.userId}`);
    }
    return await apiService(`${STATEMENTS}?pageNo=${data.page}&pageSize=${data.rows}&userId=${data.userId}`);
});

export const fetchUserNotifications = createAsyncThunk("fetchUserNotifications", async (data: any) => {
    if (data.isDeregisteredUser) {
        return await apiService(`${GET_DEREGISTERED_USER_NOTIFICATIONS}?notificationType=${data.filter}&userId=${data.userId}&pageNo=${data.page}&size=${data.rows}`);
    }
    return await apiService(`${END_USER_NOTIFICATIONS}?notificationType=${data.filter}&userId=${data.userId}&pageNo=${data.page}&size=${data.rows}`);
});

export const fetchUserBankAccountActivities = createAsyncThunk("fetchUserBankAccountActivities", async (data: any) => {
    if (data.isDeregisteredUser) {
        return await apiService(`${GET_DEREGISTERED_USER_BANK_ACCOUNT_ACTIVITY}?activityType=${data.filter}&id=${data.userId}&pageNo=${data.page}&size=${data.rows}`);
    }
    return await apiService(`${GET_USER_BANK_ACCOUNT_ACTIVITY}?activityType=${data.filter}&id=${data.userId}&pageNo=${data.page}&size=${data.rows}`);
});

const customerAccountSlice = createSlice({
    name: "customerAccount",
    initialState,
    reducers: {
        resetCustomerAccount: () => initialState,
    },
    extraReducers: (builder) => {
        //For User Profile Details
        builder.addCase(fetchUserProfile.pending, (state) => {
            state.profileLoading = true;
            state.error = null;
        });

        builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
            const data = action.payload?.data;
            state.profileLoading = false;
            state.currentCustomerDetails = {
                ...data,
                phoneNumber: data?.mobilePhone,
                status: data?.userStatus,
            };
            state.error = action.payload?.errors || action.payload?.error;
        });

        builder.addCase(fetchUserProfile.rejected, (state) => {
            state.currentCustomerDetails = null;
            state.profileLoading = false;
            state.error = "Something went wrong! Please try again.";
        });

        //For User Profile Activities
        builder.addCase(fetchUserActivities.pending, (state) => {
            state.tableLoading = true;
            state.error = null;
            state.loadingCount = state.loadingCount + 1;
        });

        builder.addCase(fetchUserActivities.fulfilled, (state, action) => {
            state.tableLoading = false;
            state.currentCustomerActivities = action.payload?.data;
            state.error = action.payload?.errors || action.payload?.error;
            state.totalRecords = action.payload?.data?.totalElements;
            state.loadingCount -= 1;
        });

        builder.addCase(fetchUserActivities.rejected, (state) => {
            state.currentCustomerActivities = [];
            state.tableLoading = false;
            state.error = "Something went wrong! Please try again.";
            state.loadingCount -= 1;
            state.totalRecords = 0;
        });

        //For User Call Summary
        builder.addCase(fetchUserCallSummary.pending, (state) => {
            state.tableLoading = true;
            state.loadingCount = state.loadingCount + 1;
        });

        builder.addCase(fetchUserCallSummary.fulfilled, (state, action) => {
            state.tableLoading = false;
            state.currentCustomerCallSummary = action.payload?.data?.content;
            state.totalRecords = action.payload?.data?.totalElements;
            state.loadingCount -= 1;
        });

        builder.addCase(fetchUserCallSummary.rejected, (state) => {
            state.currentCustomerCallSummary = [];
            state.tableLoading = false;
            state.error = "Something went wrong! Please try again.";
            state.loadingCount -= 1;
            state.totalRecords = 0;
        });

        //For User Statements
        builder.addCase(fetchUserStatement.pending, (state) => {
            state.tableLoading = true;
            state.loadingCount = state.loadingCount + 1;
        });

        builder.addCase(fetchUserStatement.fulfilled, (state, action) => {
            state.tableLoading = false;
            state.currentCustomerStatementActivities = action.payload?.data?.statementActivity;
            state.totalRecords = action.payload?.data?.totalElements;
            state.loadingCount -= 1;
        });

        builder.addCase(fetchUserStatement.rejected, (state) => {
            state.currentCustomerStatementActivities = [];
            state.tableLoading = false;
            state.error = "Something went wrong! Please try again.";
            state.loadingCount -= 1;
            state.totalRecords = 0;
        });

        //For Notifications
        builder.addCase(fetchUserNotifications.pending, (state) => {
            state.tableLoading = true;
            state.loadingCount = state.loadingCount + 1;
        });

        builder.addCase(fetchUserNotifications.fulfilled, (state, action) => {
            state.tableLoading = false;
            state.currentCustomerNotifications = action?.payload?.data?.content;
            state.totalRecords = action?.payload?.data?.totalElements;
            state.loadingCount -= 1;
        });

        builder.addCase(fetchUserNotifications.rejected, (state) => {
            state.currentCustomerNotifications = [];
            state.tableLoading = false;
            state.error = "Something went wrong! Please try again.";
            state.loadingCount -= 1;
            state.totalRecords = 0;
        });

        //For Bank Account Activities
        builder.addCase(fetchUserBankAccountActivities.pending, (state) => {
            state.tableLoading = true;
            state.loadingCount = state.loadingCount + 1;
        });

        builder.addCase(fetchUserBankAccountActivities.fulfilled, (state, action) => {
            state.tableLoading = false;
            state.currentCustomerBankAccountActivities = action?.payload?.data?.activities;
            state.totalRecords = action?.payload?.data?.totalElements;
            state.loadingCount -= 1;
        });

        builder.addCase(fetchUserBankAccountActivities.rejected, (state) => {
            state.currentCustomerBankAccountActivities = [];
            state.tableLoading = false;
            state.error = "Something went wrong! Please try again.";
            state.loadingCount -= 1;
            state.totalRecords = 0;
        });
    },
});

export const { resetCustomerAccount } = customerAccountSlice.actions;
export default customerAccountSlice.reducer;
