import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import MetadataType from "../types/metadata";
import apiService from "../../utilities/apiService";
import { METADATA } from "../../constants/apiPaths";

const initialState: MetadataType = {
    states: [],
    issueStatus: [],
    issueTypes: [],
    citizenship: [],
    suspend_reason: [],
    unsuspend_reason: [],
    close_reason: [],
    resolution_action: [],
    timezone: [],
    non_user_call_category: [],
};

export const fetchMetadata = createAsyncThunk("metadata/fetchAll", async () => {
    try {
        const response = await apiService(METADATA);
        const data = response?.data?.reduce((a: any, b: any) => {
            if (a[b.type]) {
                a[b.type].push(b);
            } else {
                a[b.type] = [b];
            }
            return a;
        }, {});
        return data;
    } catch (e) {
        console.log(e);
    }
});

const metadataSlice = createSlice({
    name: "metadata",
    initialState,
    reducers: {
        resetMetadata: () => initialState,
        setMetadata: (state: MetadataType, action: PayloadAction<any>) => {
            const payload = action.payload;
            state.states = payload.states;
            state.issueStatus = payload.status;
            state.issueTypes = payload.issue;
            state.citizenship = payload.citizenship;
            state.suspend_reason = payload.suspend_reason;
            state.unsuspend_reason = payload.unsuspend_reason;
            state.close_reason = payload.close_reason;
            state.resolution_action = payload.resolution_action?.sort((a: any, b: any) => {
                if (a.value === "Other") return 1;
                if (b.value === "Other") return -1;
                return a.id - b.id;
            });
            state.timezone = payload.timezone;
            state.non_user_call_category = payload.non_user_call_category;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchMetadata.fulfilled, (state, action) => {
            const payload = action.payload;
            state.states = payload?.states || [];
            state.issueStatus = payload?.status || [];
            state.issueTypes = payload?.issue || [];
            state.citizenship = payload?.citizenship || [];
            state.suspend_reason = payload?.suspend_reason || [];
            state.unsuspend_reason = payload?.unsuspend_reason || [];
            state.close_reason = payload?.close_reason || [];
            state.resolution_action = (payload?.resolution_action || [])?.sort((a: any, b: any) => {
                if (a.value === "Other") return 1;
                if (b.value === "Other") return -1;
                return a.id - b.id;
            });
            state.timezone = payload?.timezone || [];
            state.non_user_call_category = payload?.non_user_call_category || [];
        });
    },
});

export const { resetMetadata, setMetadata } = metadataSlice.actions;
export default metadataSlice.reducer;
