import KeyboardShortCutDetails from "../../KeyboardShortCutDetails/KeyboardShortCutDetails";

/**
 * Loader component that displays a loading animation and optionally shows keyboard shortcuts.
 *
 * @param {Object} props - The component props.
 * @param {boolean} [props.showKeyboardShortcuts=true] - Determines whether to display the keyboard shortcuts.
 * @returns {JSX.Element} The rendered Loader component.
 */
const Loader = ({ showKeyboardShortcuts = true }) => {
    return (
        <div className="flex" data-testid="loader">
            <div className={`h-2.5 w-2.5 bg-buttonBgColor rounded-full mr-1 animate-bounce`}></div>
            <div className={`h-2.5 w-2.5 bg-buttonBgColor rounded-full mr-1 animate-bounce200`}></div>
            <div className={`h-2.5 w-2.5 bg-buttonBgColor rounded-full animate-bounce400`}></div>
            {showKeyboardShortcuts && (
                <div className="fixed bottom-0 right-0 mr-4 mb-8">
                    <KeyboardShortCutDetails />
                </div>
            )}
        </div>
    );
};

export default Loader;
