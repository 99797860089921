import React, { Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Loader from "../components/shared/Loader/Loader";
import useHttp from "../hooks/useHttp";
import { GET_TIMEZONE } from "../constants/apiPaths";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store";
import { setTimezone } from "../redux/slices/timezoneDropdownSlice";
import { DEFAULT_TIMEZONE, DEFAULT_TIMEZONE_VALUE } from "../constants/stringConstant";
// Lazy-loaded components
const Dashboard = React.lazy(() => import("../components/Dashboard/Dashboard"));
const FAQs = React.lazy(() => import("../components/FAQs/FAQ"));
const CustomerAgentDashboard = React.lazy(() => import("../components/Dashboard/ManagementDashboard/CustomerAgentDashboard"));
const CustomerAgenActivities = React.lazy(() => import("../components/Dashboard/ManagementDashboard/CustomerAgentDetails/CustomerAgentDetails"));
const ManagersDashboard = React.lazy(() => import("../components/Dashboard/ManagementDashboard/ManagersDashboard"));
const CallActivities = React.lazy(() => import("../components/CallActivity/CallActivities"));
const ListOfCustomer = React.lazy(() => import("../components/ListOfCustomers/ListOfCustomer"));
const TermsAndConditionsList = React.lazy(() => import("../components/TermsAndConditions/TermsAndConditionsList"));
/**
 * Component for rendering all the routes within the application based on user roles.
 *
 * @param roleId - Role of the current user (1: Admin, 2: Manager, 3:CustomerAgent).
 * @returns JSX.Element - The rendered routes.
 */
const AllRoutes = ({ roleId }: { roleId: number }) => {
    const { apiService } = useHttp();
    const dispatch = useDispatch<AppDispatch>();
    const { idToken, userDetails } = useSelector((state: RootState) => state?.user);

    useEffect(() => {
        /**
         * Function to fetch and set the preferred timezone for the user.
         * If an error occurs, default timezone values are used.
         */
        const getTimezone = async () => {
            try {
                const timezone = await apiService(`${GET_TIMEZONE}`, { Authorization: `Bearer ${idToken}` }, "GET", null, { idToken: idToken as string });
                dispatch(setTimezone({ timezone: timezone?.data?.preferredTimeZone ?? DEFAULT_TIMEZONE, value: timezone?.data?.timezoneFormat ?? DEFAULT_TIMEZONE_VALUE }));
            } catch (error) {
                dispatch(setTimezone({ timezone: DEFAULT_TIMEZONE, value: DEFAULT_TIMEZONE_VALUE }));
            }
        };

        // Fetch timezone if user details and idToken are available
        if (userDetails && idToken) {
            getTimezone();
        }
    }, [userDetails?.userId, idToken, apiService, dispatch]);

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                {/* Routes available for all users */}
                <Route path="/dashboard/*" element={<Dashboard />} />
                <Route path="/callActivity" element={<CallActivities />} />
                <Route path="/faq" element={<FAQs />} />
                <Route path="/terms&Conditions" element={<TermsAndConditionsList />} />
                <Route path="/reset-password/*" element={<div>Hi this is reset password page</div>} />

                {/* Routes available for roles with roleId <= 2 */}
                {roleId <= 2 && <Route path="/customerSupportAgents/:agentId" element={<CustomerAgenActivities />} />}
                {roleId <= 2 && <Route path="/customerSupportAgents" element={<CustomerAgentDashboard />} />}

                {/* Route available for Admin (roleId === 1) */}
                {roleId === 1 && <Route path="/managers" element={<ManagersDashboard />} />}

                {/* Users list route */}
                <Route path="/usersList/*" element={<ListOfCustomer />} />

                {/* Redirect to dashboard for unmatched routes */}
                <Route path="/*" element={<Navigate to="/dashboard" />} />
            </Routes>
        </Suspense>
    );
};

export default AllRoutes;
