export const SEARCH_CUSTOMERS_BY_SEARCHTEXT = "/dashboard/user"; // requires searchText as query params
export const GET_USER_PROFILE_BY_ID = "/end-user/profile";
export const GET_USER_DETAILS_BY_ID = "/dashboard/activity";
export const GET_FAQs = "/dashboard/faq";
export const GET_AVENU_USER_BY_EMAIL = "/dashboard/validate/user";
export const ADMIN_INVITE_AVENU_USERS = "/admin/user/invite";
export const ADMIN_ADD_AVENU_USERS = "/admin/add/agent";
export const ADMIN_GET_ALL_CUSTOMER_EXECUTIVE = "/admin/users";
export const GET_FEATURE_LIST = "/access-control/features";
export const ADMIN_BLOCK_CUSTOMER_EXECUTIVE = "/admin/user/block"; // for block and unblock
// export const ADMIN_DELETE_CUSTOMER_EXECUTIVE = "/admin/user";
export const MANAGER_INVITE_AVENU_USERS = "/manager/user/invite";
export const MANAGER_GET_ALL_CUSTOMER_EXECUTIVE = "/manager/users";
export const MANAGER_BLOCK_CUSTOMER_EXECUTIVE = "/manager/user/block"; // for block and unblock
export const MANAGER_TOGGLE_FEATURE = "/access-control/feature";
// export const MANAGER_DELETE_CUSTOMER_EXECUTIVE = "/manager/user";
export const GENERATE_VERIFY_CUSTOMER_OTP = "/dashboard/generate/otp";
export const VALIDATE_VERIFY_CUSTOMER_OTP = "/dashboard/validate/otp";
export const LAST_LOGIN = "/dashboard/lastlogin";
export const VERIFY_USER_POST_INVITE = "/dashboard/verify/user";
export const CUSTOMER_UPDATE_PROFILE = "/end-user/update";
export const METADATA = "/metadata?type=status,states,issue,citizenship,unsuspend_reason,suspend_reason,close_reason,resolution_action,timezone,non_user_call_category";
export const CREATE_CALL_SUMMARY = "/call-summary/create";
export const ADD_FEEDBACK = "/call-summary/feedback";
export const CALL_SUMMARY = "/call-summary";
export const SUSPEND_END_USER = "/end-user/suspend";
export const CLOSE_ACCOUNT_END_USER = "/end-user/close";
export const RESET_PASSWORS_END_USER = "/end-user/reset-password";
export const STATEMENTS = "/dashboard/statements";
export const STATEMENT_PDF = "/dashboard/statement-pdf";
export const END_USER_NOTIFICATIONS = "/dashboard/notifications";
export const GET_TIMEZONE = "/dashboard/profile";
export const SET_TIMEZONE = "/dashboard/timezone";
export const GET_USER_BANK_ACCOUNT_ACTIVITY = "/dashboard/bank-account";
export const TERMS_AND_CONDTITIONS = "/terms-and-condition";
export const GET_VENU_CUSTOMER_LIST = "/dashboard/filter-user";
export const CHECK_VERIFICATION_STATUS = "/dashboard/verification/status";
//agent activities
export const GET_AGENT_PROFILE_DETAILS = "/admin/user/profile";
export const GET_CUSTOMER_AGENT_DETAILS_BY_ID = "/agent-activity";
export const CHANGE_CALL_SUMMARY_STATUS = "/call-summary/resolveCase";
export const EDIT_CALL_SUMMARY = "/call-summary/caseSummary";
export const NON_USER_CALL_SUMMARY = "/call-summary/nonUser";
export const GET_CALL_HISTORY = "/call-summary/history";
export const GET_ADMIN_CASE_SUMMARY_AGENTS = "/admin/users/list/brief";
export const GET_MANAGER_CASE_SUMMARY_AGENTS = "/manager/users/list/brief";
//DeregisteredUsers
export const GET_DEREGISTERED_USERS = "/deregister/user";
export const GET_DEREGISTERED_USER_PROFILE = "/deregister/profile";
export const GET_DEREGISTERED_USER_ACTIVITY = "/deregister/activity";
export const GET_DEREGISTERED_USER_CALL_SUMMARY = "/deregister/call-summary";
export const GET_DEREGISTERED_USER_NOTIFICATIONS = "/deregister/notifications";
export const GET_DEREGISTERED_USER_CALL_HISTORY = "/deregister/call-summary/history";
export const GET_DEREGISTERED_USER_STATEMENTS = "/deregister/statements";
export const GET_DEREGISTERED_USER_BANK_ACCOUNT_ACTIVITY = "/deregister/bank-account";
export const GET_DEREGISTERED_USER_COMMENTS = "/deregister/call-summary/comments";
export const SUBMIT_DEREGISTERED_FEEDBACK = "/deregister/feedback";
// upload Tnc
export const UPLOAD_TNC = "/admin/terms-and-condition/upload";
export const GET_TNCLIST = "/admin/terms-and-condition/history";
export const SUBMIT_IMPLEMENTED_ONDATE = "/admin/terms-and-condition/implemented-on";
//Comments
export const ADD_COMMENT = "/call-summary/comment";
export const GET_COMMENT_LIST = "/call-summary/comments";
