import { isValidPhoneNumber } from "libphonenumber-js";

/**
 * Validates a given input value based on an array of validator rules.
 *
 * @param {any} value - The input value to validate. It is first trimmed of whitespace before validation.
 * @param {Array<string>} validators - An array of strings representing the validation rules.
 * Supported rules include:
 * - "REQUIRE": Requires the value to have at least 3 characters.
 * - "EMAIL": Validates the value as an email address.
 * - "ALPHABETS": Ensures the value only contains alphabets (a-z, A-Z).
 * - "NUMBER": Ensures the value only contains digits (0-9).
 * - "PASSWORD": Requires a password with at least one uppercase letter, one lowercase letter, one digit, one special character, and 8-16 characters in length.
 * - "ZIPCODE": Validates the value as a US ZIP code (5 or 9 digits).
 * - "REASONS": Ensures the value contains only alphanumeric characters, specific symbols (&@.,:-*=+#%_ ), and spaces.
 * - "PHONE": Validates the value as a phone number using the `libphonenumber-js` library.
 * - "MIN_LENGTH#X": Requires the value to have a minimum length of X characters.
 * - "MAX_LENGTH#X": Requires the value to have a maximum length of X characters.
 * - "GREATER_THAN#X": Ensures the value is greater than X.
 * - "SMALLER_THAN#X": Ensures the value is smaller than X.
 *
 * @returns {boolean} - Returns `true` if all validators pass, otherwise returns `false`.
 *
 * @example
 * ```ts
 * const isValid = validate("example@gmail.com", ["EMAIL", "REQUIRE"]);
 * console.log(isValid); // true
 * ```
 */
function validate(value: any, validators: Array<string>) {
    value = value.trim();

    let isValid = true;
    for (const validator of validators) {
        switch (validator) {
            case "REQUIRE":
                isValid = isValid && value.length >= 3;
                break;
            case "EMAIL":
                isValid = isValid && /^\S+@\S+\.(\S){2,15}$/.test(value);
                break;
            case "ALPHABETS":
                isValid = isValid && /^[a-z|A-Z]*$/.test(value);
                break;
            case "NUMBER":
                isValid = isValid && /^\d*$/.test(value);
                break;
            case "PASSWORD":
                isValid = isValid && /^((?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16})*$/.test(value);
                break;
            case "ZIPCODE":
                isValid = isValid && /^\d{5}$|^\d{9}$/.test(value);
                break;
            case "REASONS":
                isValid = isValid && /^[a-zA-Z0-9&@.,:\-*=+#%_ ]*$/.test(value);
                break;
            case "PHONE":
                isValid = isValid && isValidPhoneNumber("+" + value);
                break;
        }

        if (validator.startsWith("MIN_LENGTH#")) isValid = isValid && value.length >= validator.split("#")[1];
        else if (validator.startsWith("MAX_LENGTH#")) isValid = isValid && value.length <= validator.split("#")[1];
        else if (validator.startsWith("GREATER_THAN#")) isValid = isValid && value >= +validator.split("#")[1];
        else if (validator.startsWith("SMALLER_THAN#")) isValid = isValid && value <= +validator.split("#")[1];
    }
    return isValid;
}

export default validate;
