import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setShowModal } from '../../../redux/slices/draggableModalSlice';
import StatusMessagesComponentRedux from '../../../hooks/useStatusMessageRedux';

/**
 * Props for the DraggableModal component.
 *
 * @interface DraggableModalProps
 * @property {React.ReactNode} children - The content to be displayed inside the modal.
 * @property {string} [className] - Optional additional class names for styling.
 * @property {() => void} onCloseModal - Callback function to be called when the modal is closed.
 */
interface DraggableModalProps {
    children: React.ReactNode;
    className?: string;
    onCloseModal: () => void;
}

/**
 * A draggable modal component that can be moved around the screen.
 *
 * @param {DraggableModalProps} props - The properties for the component.
 * @returns {JSX.Element | null} The rendered modal or null if not shown.
 */
const DraggableModal = ({ children, className = '', onCloseModal = () => null }: DraggableModalProps) => {
    const dispatch = useDispatch();
    const { show, statusComponentType } = useSelector((state: RootState) => state.draggableModal);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [position, setPosition] = useState<{ left: number; top: number }>({ left: 0, top: 0 });
    const [offset, setOffset] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleMouseMove(evt: MouseEvent) {
            if (isDragging && modalRef.current) {
                evt.preventDefault();
                const modalElement = modalRef.current;
                const rect = modalElement.getBoundingClientRect();
                let newLeft = evt.clientX - offset.x;
                let newTop = evt.clientY - offset.y;
                const viewportWidth = window.innerWidth;
                const viewportHeight = window.innerHeight;
                const modalWidth = rect.width;
                const modalHeight = rect.height;
                if (newLeft < 0) newLeft = 0;
                if (newTop < 0) newTop = 0;
                if (newLeft + modalWidth > viewportWidth) newLeft = viewportWidth - modalWidth;
                if (newTop + modalHeight > viewportHeight) newTop = viewportHeight - modalHeight;
                setPosition({ left: newLeft, top: newTop });
            }
        }

        function handleMouseUp() {
            if (isDragging) {
                setIsDragging(false);
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
            }
        }

        if (isDragging) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, offset]);

    useEffect(() => {
        // Center the modal initially
        if (show && modalRef.current) {
            const modalElement = modalRef.current;
            const modalWidth = modalElement.offsetWidth;
            const modalHeight = modalElement.offsetHeight;

            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;

            const newLeft = Math.max(0, (viewportWidth - modalWidth) / 2);
            const newTop = Math.max(0, (viewportHeight - modalHeight) / 2);

            setPosition({ left: newLeft, top: newTop });
        }
    }, [show]);

    function handlePointerDown(evt: React.PointerEvent<HTMLDivElement>) {
        if (evt.button === 0) {
            setIsDragging(true);
            const rect = evt.currentTarget.getBoundingClientRect();
            setOffset({ x: evt.clientX - rect.left, y: evt.clientY - rect.top });
        }
    }

    const closeModal = () => {
        dispatch(setShowModal(false));
        onCloseModal();
    };

    if (!show) {
        return null;
    } 

    return (
        <div
            aria-modal="true"
            ref={modalRef}
            style={{
                cursor: isDragging ? 'move' : '',
                zIndex: 2000,
                height: '80vh',
                width: '100vh',
                overflow: 'hidden',
                top: `${position.top}px`,
                left: `${position.left}px`,
                userSelect: 'none',
                backgroundColor: 'white',
                borderRadius: '16px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
            }}
            onPointerDown={handlePointerDown}
            tabIndex={-1}
            className={`fixed m-auto text-center ${className}`}
        >
            <div className="text-center max-h-[80vh] py-10 overflow-auto text-black w-full relative">
                {statusComponentType === 'redux' && (
                    <div className="mb-4 mt-2 px-10" data-testid="StatusMessagesComponentRedux">
                        <StatusMessagesComponentRedux className="status-messages" />
                    </div>
                )}
                <div className="flex justify-end top-8 items-center">
                    <button onClick={closeModal} className="mt-3 mr-10" data-testid="crossIcon">
                        <span className="pi pi-times 2xl:text-lg text-xs text-primaryTextColor relative z-50"></span>
                    </button>
                </div>
                <div className="2xl:-mt-9 -mt-7">{children}</div>
            </div>
        </div>
    );
};

export default DraggableModal;
