import Cookies from "js-cookie";
import { googleLookUp } from "./firebase";
import refreshTokenEveryApiCall from "./refreshToken";
import { clearAllCookies } from "./resuableColumnFunctions";

/**
 * Makes an API request with authentication, refreshing the token if needed.
 *
 * @param {string} path - The API endpoint to call.
 * @param {object} [headers] - Optional additional headers to include in the request.
 * @param {string} [method="GET"] - HTTP method (GET, POST, etc.).
 * @param {any} [body] - Optional request body (for POST or PUT requests).
 * @param {AbortSignal} [signal] - Optional abort signal to cancel the request.
 * @returns {Promise<any | null>} The response data from the API or `null` if an error occurs (e.g., unauthorized).
 *
 * @throws Will throw an error if the fetch request fails (e.g., network error).
 *
 * @example
 * ```ts
 * const response = await apiService("/users", { Authorization: "Bearer token" }, "GET");
 * console.log(response);
 * ```
 */
const apiService = async (path: string, headers?: object, method?: string, body?: any, signal?: AbortSignal) => {
    const url = process.env.REACT_APP_API_BASE_URL + path;

    let headerPayload: any = {
        "Content-Type": "application/json",
    };

    // Get auth token from cookies or storage
    const storage = JSON.parse(Cookies.get("persist:user") ?? "{}");
    if (Object.keys(storage).length === 0) {
        signOut();
        return null;
    }
    const idToken = JSON.parse(storage.idToken);
    const refreshToken = JSON.parse(storage.refreshToken);

    // Verify the token and refresh if necessary
    await googleLookUp(idToken, signOut);
    if (idToken) {
        headerPayload = {
            ...headerPayload,
            Authorization: "Bearer " + idToken,
        };
    }

    headerPayload = {
        ...headerPayload,
        ...headers,
    };

    const response = await fetch(url, {
        method: method || "GET",
        headers: headerPayload,
        body: body ? JSON.stringify(body) : null,
        signal: signal,
        credentials: "omit",
    });

    if (response.status === 401) {
        // Sign out if unauthorized
        signOut();
        return null;
    }

    // Refresh token if required
    const data = await refreshTokenEveryApiCall({ refreshToken });
    if (data.id_token) {
        const updatedIdToken = `"${data.id_token}"`;
        const updatedStorage = {
            ...storage,
            idToken: updatedIdToken,
        };
        Cookies.set("persist:user", JSON.stringify(updatedStorage), {
            secure: true,
            sameSite: "strict",
        });
    }

    return await response.json();
};

/**
 * Signs the user out by clearing cookies and session storage, and redirects to the login page.
 *
 * This function is typically called when the authentication token is invalid or expired.
 *
 * @example
 * ```ts
 * signOut(); // Logs the user out and redirects to login page.
 * ```
 */
export const signOut = () => {
    clearAllCookies();
    sessionStorage.clear();
    window.location.href = "/login";
};

export default apiService;
