/**
 * DisclaimerModal component that displays a disclaimer message
 * regarding the use of the computer system owned by MainStreet Bank.
 * It informs users about the monitoring and potential prosecution
 * for unauthorized access.
 * @returns A React component rendering the disclaimer message.
 */
const DisclaimerModal = () => {
    return (
        <div className="px-10">
            <p className="mb-10 whitespace-normal">
                This is a private computer system and is the property of MainStreet Bank. This computer system, including all related equipment, is provided only for authorized use. Any or all uses of
                this system and all files on this system may be intercepted, monitored, recorded, copied, audited, inspected, and disclosed to MainStreet Bank, and law enforcement personnel.
                Individuals attempting unauthorized access will be prosecuted.
            </p>
            <p>
                <i>Clicking on OK indicates your acceptance of this information.</i>
            </p>
        </div>
    );
};

export default DisclaimerModal;
