import { useDispatch } from "react-redux";
import { resetCustomerAccount } from "../../redux/slices/customerAccountSlice";
import { resetSearchCustomer } from "../../redux/slices/searchCustomerSlice";
import ManagerSVG from "../shared/public/svgs/ManagerSVG/ManagerSVG";
import UsersListSVG from "../shared/public/svgs/UsersListSVG/UsersListSVG";
import CustomerAgentSVG from "../shared/public/svgs/CustomerAgentSVG/CustomerAgentSVG";

type ScreensType = "dashboard" | "managers" | "cse" | "faqs" | "log-out" | "callCases" | "usersList"|"terms&Conditions";

const screensByRole: Record<number, ScreensType[]> = {
    1: ["dashboard", "managers", "cse", "faqs", "log-out", "callCases", "usersList","terms&Conditions"],
    2: ["dashboard", "cse", "faqs", "log-out", "callCases", "usersList"],
    3: ["dashboard", "faqs", "log-out", "callCases", "usersList"],
};

const SIDEBAR_LINKS = ({ roleId }: { roleId: number }) => {
    const dispatch = useDispatch();

    const SIDEBAR_NAVLINKS = [
        {
            id: "dashboard",
            path: "/dashboard",
            title: "Search",
            icons: "pi-search",
            onClick: () => {
                dispatch(resetCustomerAccount());
                dispatch(resetSearchCustomer());
            },
        },
        {
            id: "callCases",
            path: "/callActivity",
            title: "Case activities",
            icons: "pi-file",
            onClick: () => {
                dispatch(resetSearchCustomer());
                dispatch(resetCustomerAccount());
            },
        },
        {
            id: "usersList",
            path: "/usersList",
            title: "List of customers",
            icons: UsersListSVG,
            descriptions: "UsersListSVG",
            onClick: () => {
                dispatch(resetCustomerAccount());
                dispatch(resetSearchCustomer());
            },
        },
        {
            id: "terms&Conditions",
            path: "/terms&Conditions",
            title: "Terms & conditions",
            icons: "pi-file-edit",
        },
        {
            id: "managers",
            path: "/managers",
            title: "Managers",
            icons: ManagerSVG,
            descriptions: "ManagerSVG",
        },
        {
            id: "cse",
            path: "/customerSupportAgents",
            title: "CS agents",
            icons: CustomerAgentSVG,
            descriptions: "CustomerAgentSVG",
        },
        {
            id: "faqs",
            path: "/faq",
            title: "FAQs",
            icons: "pi-question-circle",
        },
    ];

    const screens = screensByRole[roleId];

    const filteredSidebarLinks = SIDEBAR_NAVLINKS.filter((link) => screens.includes(link.id as ScreensType));
    return filteredSidebarLinks;
};
export default SIDEBAR_LINKS;
