import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../utilities/apiService";
import { GET_DEREGISTERED_USERS, GET_VENU_CUSTOMER_LIST } from "../../constants/apiPaths";
import VenuCustomerListType from "../types/venuCustomerList";

const initialState: VenuCustomerListType = {
    VenuCustomer: null,
    isLoading: false,
    totalRecords: 0,
    isDeregisteredUsersLoading: false,
    DeregisteredUsers: null,
    totalDeregisteredUsers: 0,
    error: null,
};

export const fetchVenuCustomerList = createAsyncThunk("fetchVenuCustomerList", async (data: any) => {
    return await apiService(
        `${GET_VENU_CUSTOMER_LIST}?pageNo=${data.pageNo}&size=${data.size}&status=${data.status}` +
            (data.searchText ? `&searchText=${data.searchText}` : "") +
            (data.agentId ? `&agentId=${data.agentId}` : "")
    );
});

export const fetchDeregisteredUsers = createAsyncThunk("fetchDeregisteredUsers", async (data: any) => {
    return await apiService(`${GET_DEREGISTERED_USERS}?pageNo=${data.pageNo}&pageSize=${data.size}` + (data.searchText ? `&searchText=${data.searchText}` : ""));
});

const venuCustomerListSlice = createSlice({
    name: "venuCustomerList",
    initialState,
    reducers: {
        resetVenuCustomerList: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchVenuCustomerList.pending, (state) => {
            state.isLoading = true;
            state.error = null;
            state.totalRecords = 0;
        });
        builder.addCase(fetchVenuCustomerList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.VenuCustomer = action.payload?.data?.content;
            state.totalRecords = action?.payload?.data?.totalElements;
            state.error = action.payload?.errors || action.payload?.error;
        });
        builder.addCase(fetchVenuCustomerList.rejected, (state) => {
            state.isLoading = false;
            state.error = "Something went wrong! Please try again.";
            state.totalRecords = 0;
        });

        //DeregisteredUsers
        builder.addCase(fetchDeregisteredUsers.pending, (state) => {
            state.isDeregisteredUsersLoading = true;
            state.error = null;
            state.totalDeregisteredUsers = 0;
        });
        builder.addCase(fetchDeregisteredUsers.fulfilled, (state, action) => {
            state.isDeregisteredUsersLoading = false;
            state.DeregisteredUsers = action.payload?.data?.content;
            state.totalDeregisteredUsers = action?.payload?.data?.totalElements;
            state.error = action.payload?.errors || action.payload?.error;
        });
        builder.addCase(fetchDeregisteredUsers.rejected, (state) => {
            state.isDeregisteredUsersLoading = false;
            state.error = "Something went wrong! Please try again.";
            state.totalDeregisteredUsers = 0;
        });
    },
});

export const { resetVenuCustomerList } = venuCustomerListSlice.actions;
export default venuCustomerListSlice.reducer;
