import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import useForm from "../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { fetchMetadata } from "../../redux/slices/metadataSlice";
import { useEffect } from "react";
import useHttp from "../../hooks/useHttp";
import SpinningLoader from "../shared/Loader/SpinningLoader";
import { setTimezone } from "../../redux/slices/timezoneDropdownSlice";
import { SET_TIMEZONE } from "../../constants/apiPaths";
import { UserDataType } from "../../redux/types/user";
import { DEFAULT_TIMEZONE } from "../../constants/stringConstant";

const INITIAL_FORM_VALUE = {
    preferedTimezone: "",
};

const INITIAL_FORM_ERROR = {
    preferedTimezone: "",
};

/**
 * Props for the TimezoneModal component.
 *
 * @interface TimezoneModalProps
 * @property {(show: boolean) => void} setShowModal - Function to control the visibility of the modal.
 * @property {UserDataType} userDetails - Details of the user for whom the timezone is being set.
 * @property {(show: boolean) => void} setSidebarState - Function to control the state of the sidebar.
 */
interface TimezoneModalProps {
    setShowModal: (show: boolean) => void;
    userDetails: UserDataType;
    setSidebarState: (show: boolean) => void;
}

/**
 * Component for selecting and setting the user's timezone preference.
 *
 * @param {TimezoneModalProps} props - The properties for the TimezoneModal component.
 * @returns {JSX.Element} The rendered TimezoneModal component.
 */
const TimezoneModal = ({ setShowModal, userDetails, setSidebarState }: TimezoneModalProps) => {
    const { formValues, formErrors, showFormErrors, setShowFormErrors, onSelectChange } = useForm(INITIAL_FORM_VALUE, INITIAL_FORM_ERROR);
    const { timezone } = useSelector((state: RootState) => state.metadata);
    const { preferedTimezone } = useSelector((state: RootState) => state.timezone);
    const { isLoading, error, apiService } = useHttp();
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(fetchMetadata());
    }, []);

    useEffect(() => {
        const timezone = preferedTimezone || DEFAULT_TIMEZONE;
        onSelectChange("preferedTimezone", timezone);
    }, [preferedTimezone]);

    /**
     * Handles saving the selected timezone preference.
     *
     * @async
     * @function handleSave
     */
    const handleSave = async () => {
        setShowFormErrors(true);
        if (!Object.values(formErrors).some((error) => error)) {
            try {
                const selectedTimezoneValue = timezone.find((zone) => zone.key === formValues.preferedTimezone);
                const body = {
                    timezone: formValues.preferedTimezone,
                };
                await apiService(`${SET_TIMEZONE}`, {}, "POST", body);
                dispatch(setTimezone({ timezone: formValues.preferedTimezone, value: selectedTimezoneValue?.value }));
                setShowModal(false);
                setSidebarState(false);
            } catch (error) {
                // error handled by useHttp hook error variable
            }
        }
    };

    return (
        <>
            <div className="text-secondaryTextColor font-extrabold w-full text-3xl 2xl:text-5xl">Timezone preference</div>
            <div className="mt-8 2xl:mt-12 w-3/4 2xl:w-3/5 text-center m-auto ">
                <div className="my-6 max-2xl:mb-4">
                    <div className="mb-5 text-left font-medium text-lg max-2xl:mb-2 max-2xl:text-base">Select your preferred timezone</div>
                    <Dropdown
                        value={formValues.preferedTimezone}
                        onChange={(e: DropdownChangeEvent) => onSelectChange("preferedTimezone", e.target.value)}
                        options={timezone.map((zone: any) => ({ label: zone.key, value: zone.key }))}
                        optionLabel="label"
                        placeholder="Select an timezone"
                        className={`w-full text-left ${(formErrors.preferedTimezone && showFormErrors) || error ? "p-invalid " : ""}`}
                        pt={{
                            panel: {
                                style: { maxWidth: "300px" },
                            },
                        }}
                    />
                    {formErrors.preferedTimezone && showFormErrors && <div className=" text-errorMessageColor text-left text-sm mb-2 mt-2"> Please select an timezone</div>}
                    {error && <div className="text-errorMessageColor text-sm mb-5 mt-2 text-left">Something went wrong. Failed to update.</div>}
                </div>
            </div>
            <div className="w-full text-center mt-12 max-2xl:mt-8">
                <button className={"py-4 w-2/5 rounded-full font-semibold whitespace-nowrap mx-auto bg-buttonBgColor text-white max-2xl:py-3"} onClick={handleSave} disabled={isLoading}>
                    {isLoading ? <SpinningLoader /> : "Save"}
                </button>
            </div>
        </>
    );
};

export default TimezoneModal;
