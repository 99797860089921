import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../utilities/apiService";
import { GET_AGENT_PROFILE_DETAILS, GET_CUSTOMER_AGENT_DETAILS_BY_ID } from "../../constants/apiPaths";
import CustomerAgentAcountDetailsType from "../types/customerAgent";
const initialState: CustomerAgentAcountDetailsType = {
    currentCustomerAgentDetails:null,
    currentCustomerAgentActivities:[],
    totalRecords: 0,
    profileLoading: false,
    tableLoading: false,
    error: null,
    loadingCount:0
};

export const fetchCustomerAgentProfile = createAsyncThunk("fetchCustomerAgentProfile", async (data: any) => {
    return await apiService(`${GET_AGENT_PROFILE_DETAILS}?agentId=${data.agentId}`);
});
let controller = new AbortController();
export const fetchCustomerAgentActivities = createAsyncThunk("fetchCustomerAgentActivities", async (data: any) => {
    controller.abort(); // Cancel previous request
    controller = new AbortController(); // Create a new abort controller for the current request
    try {
        const { agentId, pageNo, size, activities } = data;
        const activityList = activities.join("%2C");
        const response = apiService(`${GET_CUSTOMER_AGENT_DETAILS_BY_ID}?agentId=${agentId}&filter=${activityList}&pageNo=${pageNo}&size=${size}`, {}, "GET", null, controller.signal);
        return response; 
    } catch (err:any) {
        if (err.name === "AbortError") {
            console.log("Request aborted");
        } else {
            throw err;
        }
    }
    
});

const customerAgentAcountSlice=createSlice({
    name:'customerAgentAccount',
    initialState,
    reducers: {
        resetCustomerAgentAccount: () => initialState,
    },
    extraReducers:(builder)=>{

        // Fetch customerAgentActivities
        builder.addCase(fetchCustomerAgentActivities.pending, (state) => {
            state.tableLoading = true;
            state.error = null;
            state.loadingCount = state.loadingCount + 1;
        });

        builder.addCase(fetchCustomerAgentActivities.fulfilled, (state, action) => {
            state.tableLoading = false;
            state.currentCustomerAgentActivities = action.payload?.data;
            state.error = action.payload?.errors || action.payload?.error;
            state.loadingCount = state.loadingCount - 1;
        });

        builder.addCase(fetchCustomerAgentActivities.rejected, (state) => {
            state.currentCustomerAgentActivities = [];
            state.tableLoading = false;
            state.error = "Something went wrong! Please try again.";
            state.loadingCount = state.loadingCount -1;
           
        });
        // Fetch customer Agent profile

        builder.addCase(fetchCustomerAgentProfile.pending, (state) => {
            state.profileLoading = true;
            state.error = null;
        });

        builder.addCase(fetchCustomerAgentProfile.fulfilled, (state, action) => {
            state.profileLoading = false;
            state.currentCustomerAgentDetails = action.payload?.data;
            state.error = action.payload?.errors || action.payload?.error;
        });

        builder.addCase(fetchCustomerAgentProfile.rejected, (state) => {
            state.currentCustomerAgentDetails = null;
            state.profileLoading = false;
            state.error = "Something went wrong! Please try again.";
        });

    }
})

export const { resetCustomerAgentAccount } = customerAgentAcountSlice.actions;
export default customerAgentAcountSlice.reducer;