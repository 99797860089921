import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../utilities/apiService";
import FAQDetailsType from "../types/faq";
import { GET_FAQs } from "../../constants/apiPaths";
const initialState: FAQDetailsType = {
    FAQs: null,
    isLoading: false,
    error: null,
};
export const fetchFAQ = createAsyncThunk("fetchFAQ", async (data: any) => {
    return await apiService(`${GET_FAQs}?pageNo=${data.pageNo}&size=${data.size}` + (data.searchText ? `&searchText=${data.searchText}` : "") + (data.filter ? `&filterText=${data.filter}` : ""));
});

const faqSlice = createSlice({
    name: "faq",
    initialState,
    reducers: {
        resetFAQ: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchFAQ.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchFAQ.fulfilled, (state, action) => {
            state.isLoading = false;
            state.FAQs = action.payload?.data;
            state.error = action.payload?.errors || action.payload?.error;
        });
        builder.addCase(fetchFAQ.rejected, (state) => {
            state.isLoading = false;
            state.error = "Something went wrong! Please try again.";
        });
    },
});

export const { resetFAQ } = faqSlice.actions;
export default faqSlice.reducer;
