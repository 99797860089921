import { useState } from "react";
import ReactHotkeys from "react-hot-keys";
import KeyboardSVG from "../shared/public/svgs/KeyboardSVG/KeyboardSVG";
import ShiftKeySVG from "../shared/public/svgs/ShiftKeySVG/ShiftKeySVG";
import OptionKeySVG from "../shared/public/svgs/OptionKeySVG/OptionKeySVG";

/**
 * Component that displays keyboard shortcut details and allows toggling visibility
 * of the shortcuts using hotkeys.
 * @returns A React component for displaying keyboard shortcuts.
 */
const KeyboardShortCutDetails = () => {
    const [show, setShow] = useState(false);
    const isMac = ((navigator as any)?.userAgentData?.platform || navigator?.userAgent || "").toLowerCase().includes("mac");

    return (
        <button
            className="text-right group"
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            <ReactHotkeys
                keyName="shift+alt+k"
                onKeyUp={() => setShow(state => !state)}
            />
            <ReactHotkeys
                keyName="esc"
                onKeyUp={() => setShow(false)}
            />
            {show &&
                <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute right-0 bottom-0 shadow-base bg-white p-12 py-6 rounded-2xl mb-16 text-center">
                    <h3 className="font-extrabold text-secondaryTextColor text-3xl">Keyboard shortcuts</h3>
                    <div className="flex justify-between text-sm">
                        <div className="font-medium mr-4">
                            <ul className="*:whitespace-nowrap *:my-4 *:flex *:justify-between">
                                <li> <span className="mr-12"><kbd>tab</kbd></span> <span>navigation through "<b>Tab</b>" key.</span></li>
                                <li> <span className="mr-12"><kbd><ShiftKeySVG /> shift</kbd> + <kbd>F</kbd></span> <span>focus on the search bar</span></li>
                                <li> <span className="mr-12"><kbd><ShiftKeySVG /> shift</kbd> + <kbd>V</kbd></span> <span>open verify customer</span></li>
                                <li> <span className="mr-12"><kbd>{isMac && <OptionKeySVG />} {isMac ? "option" : "alt"}</kbd> + <kbd>O</kbd></span> <span>open call summary</span></li>
                                <li> <span className="mr-12"><kbd>{isMac && <OptionKeySVG />} {isMac ? "option" : "alt"}</kbd> + <kbd>S</kbd></span> <span>save call summary</span></li>
                                <li> <span className="mr-12"><kbd><ShiftKeySVG /> shift</kbd> + <kbd>{isMac && <OptionKeySVG />} {isMac ? "option" : "alt"}</kbd> + <kbd>S</kbd></span> <span>open search</span></li>
                                <li> <span className="mr-12"><kbd><ShiftKeySVG /> shift</kbd> + <kbd>{isMac && <OptionKeySVG />} {isMac ? "option" : "alt"}</kbd> + <kbd>A</kbd></span> <span>open agents</span></li>
                                <li> <span className="mr-12"><kbd><ShiftKeySVG /> shift</kbd> + <kbd>{isMac && <OptionKeySVG />} {isMac ? "option" : "alt"}</kbd> + <kbd>M</kbd></span> <span>open managers</span></li>
                                <li> <span className="mr-12"><kbd><ShiftKeySVG /> shift</kbd> + <kbd>{isMac && <OptionKeySVG />} {isMac ? "option" : "alt"}</kbd> + <kbd>C</kbd></span> <span>open call activities</span></li>
                                <li> <span className="mr-12"><kbd><ShiftKeySVG /> shift</kbd> + <kbd>{isMac && <OptionKeySVG />} {isMac ? "option" : "alt"}</kbd> + <kbd>F</kbd> or <kbd>{isMac && <OptionKeySVG />} {isMac ? "option" : "alt"}</kbd> + <kbd>F</kbd></span> <span>open FAQs</span></li>
                                <li> <span className="mr-12"><kbd>esc</kbd></span> <span>close popup</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
            <div className={`z-[2000] bg-filterBackgroundColor p-4 rounded-full font-medium overflow-hidden ${show ? "text-buttonBgColor" : "text-primaryTextColor"}`} >
                <KeyboardSVG color={show ? "#00A88F" : "#767676"} /> ( <ShiftKeySVG color={show ? "#00A88F" : "#767676"} />  + {isMac ? <OptionKeySVG color={show ? "#00A88F" : "#767676"} /> : "alt"} + K )
            </div>
        </button>
    );
}

export default KeyboardShortCutDetails;