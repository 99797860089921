import moment from "moment-timezone";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { DEFAULT_TIMEZONE_VALUE } from "../../../constants/stringConstant";

/**
 * Formats a date based on the provided parameters.
 *
 * @param {number | null} date - The date to format, as a timestamp (milliseconds since epoch).
 * @param {string} preferedTimezoneValue - The preferred timezone to format the date.
 * @param {boolean} [onlyDate=false] - Whether to format the output as only the date (true) or include time (false).
 * @param {string} [variant] - An optional variant that alters the date format (e.g., "secondary").
 * @returns {string} The formatted date string or "N/A" if the date is invalid.
 */
export const dateFormatter = (date: number | null, preferedTimezoneValue: string, onlyDate?: boolean, variant?: string) => {
    if (!date || date.toString() === "null") {
        return "N/A";
    }
    if (onlyDate) {
        const momentDate = moment(Number(date))?.utc();
        if (variant === "secondary") {
            return momentDate?.format("D MMMM YYYY");
        }
        return momentDate?.format("MMMM D, YYYY");
    }
    const momentDate = moment(Number(date))?.tz(preferedTimezoneValue ?? DEFAULT_TIMEZONE_VALUE);
    return momentDate?.format("MMM D, YYYY [at] hh:mm:ss A z");
};

/**
 * FormatDate component that displays a formatted date based on the provided props.
 *
 * @param {Object} props - The properties for the FormatDate component.
 * @param {number | null} props.date - The date to format, as a timestamp (milliseconds since epoch).
 * @param {boolean} [props.onlyDate=false] - Whether to display only the date or include time.
 * @param {string} [props.variant] - An optional variant that alters the date format.
 * @returns {JSX.Element} The rendered FormatDate component with the formatted date.
 */
const FormatDate = ({ date, onlyDate, variant }: { date: number | null; onlyDate?: boolean; variant?: string }) => {
    const { preferedTimezoneValue } = useSelector((state: RootState) => state.timezone);
    const timezone = preferedTimezoneValue && preferedTimezoneValue.trim() !== "" ? preferedTimezoneValue : DEFAULT_TIMEZONE_VALUE;
    return <span> {dateFormatter(date, timezone, onlyDate, variant)}</span>;
};
export default FormatDate;
