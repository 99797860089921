import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiService from "../../utilities/apiService";
import CallActivityDetailsType from "../types/callActivity";
import { CALL_SUMMARY, NON_USER_CALL_SUMMARY } from "../../constants/apiPaths";

const initialState: CallActivityDetailsType = {
    CallCases: null,
    NonUserCallCases: null,
    isLoading: false,
    error: null,
    totalRecords: 0,
};

export const fetchCases = createAsyncThunk("fetchCases", async (data: any) => {
    const statusIds = data.statusIds.join(",") || [5, 6].join(",");
    return await apiService(
        `${CALL_SUMMARY}?pageNo=${data.pageNo}&pageSize=${data.size}&statusIds=${statusIds}` +
            (data.searchText ? `&searchText=${data.searchText}` : "") +
            (data.agentId ? `&isMyCase=true` : "&isMyCase=false")
    );
});

export const fetchNonUserCallCases = createAsyncThunk("fetchNonUserCallCases", async (data: any) => {
    return await apiService(
        `${NON_USER_CALL_SUMMARY}?pageNo=${data.pageNo}&pageSize=${data.size}` + (data.searchText ? `&callId=${data.searchText}` : "") + (data.agentId ? `&isMyCase=true` : "&isMyCase=false")
    );
});

const callActivitySlice = createSlice({
    name: "callCases",
    initialState,
    reducers: {
        resetCallCases: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCases.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchCases.fulfilled, (state, action) => {
            state.isLoading = false;
            state.CallCases = action.payload?.data;
            state.error = action.payload?.errors || action.payload?.error;
            state.totalRecords = action.payload?.data?.totalElements;
        });
        builder.addCase(fetchCases.rejected, (state) => {
            state.isLoading = false;
            state.error = "Something went wrong! Please try again.";
            state.totalRecords = 0;
        });

        //for non users
        builder.addCase(fetchNonUserCallCases.pending, (state) => {
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(fetchNonUserCallCases.fulfilled, (state, action) => {
            state.isLoading = false;
            state.NonUserCallCases = action.payload?.data;
            state.error = action.payload?.errors || action.payload?.error;
            state.totalRecords = action.payload?.data?.totalElements;
        });
        builder.addCase(fetchNonUserCallCases.rejected, (state) => {
            state.isLoading = false;
            state.error = "Something went wrong! Please try again.";
            state.totalRecords = 0;
        });
    },
});

export const { resetCallCases } = callActivitySlice.actions;
export default callActivitySlice.reducer;
