/**
 * Capitalizes the first letter of each word in a camelCase or PascalCase string.
 *
 * @param {string} [str=""] - The input string to transform.
 * @returns {string} The transformed string with the first letter of each word capitalized and split by spaces.
 *
 * @example
 * ```ts
 * capitalFirstLetter("helloWorld"); // "Hello World"
 * capitalFirstLetter("MyExampleString"); // "My Example String"
 * ```
 */
export const capitalFirstLetter = (str = "") => {
    const string = str?.toString();
    let words = string?.split(/(?=[A-Z])/);
    words = words?.map((word = "") => word?.charAt(0)?.toUpperCase() + word?.slice(1));
    return words?.join(" ");
};

/**
 * Capitalizes the first letter of a sentence.
 *
 * @param {string} [str=""] - The input string to transform.
 * @returns {string} The string with the first letter capitalized.
 *
 * @example
 * ```ts
 * capitalizeFirstLetterOfSentence("hello world"); // "Hello world"
 * capitalizeFirstLetterOfSentence("already Capitalized"); // "Already Capitalized"
 * ```
 */
export const capitalizeFirstLetterOfSentence = (str = "") => {
    if (str?.length === 0 || str === null) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
};
