/* eslint-disable @typescript-eslint/ban-types */
import { Fragment, useEffect } from "react";
import ReactHotkeys from "react-hot-keys";

/**
 * Represents the props for the Modal component.
 */
export type ModalType = {
    /** Indicates whether the modal is visible. */
    show: boolean;
    /** The content to be displayed inside the modal. */
    children: any;
    /** Additional class names for styling the modal. */
    className?: string;
    /** Function to set the modal's visibility state. */
    setShowModal: Function;
    /** Function to execute when the modal is closed. */
    onCloseModal?: Function;
    /** The title of the modal. */
    title?: string;
    /** Text for the first button in the modal. */
    firstButton?: string;
    /** Function to execute when the first button is clicked. */
    onClickFirstButton?: Function;
    /** Indicates if the modal is displaying a message. */
    isMessage?: boolean;
    /** A component to be displayed for status messages. */
    statusComponent?: React.ComponentType<any>;
    /** Indicates whether to wait before closing the modal. */
    waitCloseModal?: boolean;
};

/**
 * A modal component for displaying content and actions.
 *
 * @param {ModalType} props - The properties for the modal.
 * @returns {JSX.Element} The rendered modal component.
 */
const Modal = ({
    show,
    children,
    setShowModal,
    onCloseModal = () => null,
    title = "",
    className = "",
    firstButton,
    onClickFirstButton = () => null,
    isMessage,
    statusComponent: StatusComponent,
    waitCloseModal
}: ModalType) => {
    useEffect(() => {
        setShowModal(show);
    }, [show]);

    function closeModal() {
        if (waitCloseModal) {
            return
        }
        setShowModal(false);
        onCloseModal();
    }

    if (!show) {
        return <div></div>;
    }

    const firstButtonClickHandler = async () => {
        closeModal();
        onClickFirstButton();
    };

    return (
        <Fragment>
            <ReactHotkeys keyName="esc" onKeyDown={closeModal} />
            <button className="z-[2000] fixed inset-0 bg-black opacity-30" onClick={closeModal} data-testid="backdrop"></button>
            <div className={`z-[2000] fixed m-auto text-center top-1/2 bg-white rounded-2xl -translate-y-1/2 shadow-modal inset-x-0 w-[45%] max-h-[95vh] ${className}`}>
                <div className="text-center max-h-[90vh] py-10 overflow-auto m-auto text-black w-full relative">
                    {isMessage && StatusComponent && (
                        <div className="mb-4 mt-2 px-10">
                            <StatusComponent />
                        </div>
                    )}
                    <div className="flex justify-between mr-10 items-center">
                        <div className="2xl:text-4xl text-secondaryTextColor font-extrabold w-full text-2xl">{title}</div>
                        <button onClick={closeModal} className="mt-3" data-testid="crossIcon">
                            <span className="pi pi-times 2xl:text-lg/none text-xs text-primaryTextColor relative z-50"></span>
                        </button>
                    </div>
                    <div className="2xl:-mt-9 -mt-7">{children}</div>
                    {firstButton && (
                        <div className="flex justify-center mt-4">
                            <button className="bg-buttonBgColor p-5 rounded-full px-20 text-white font-medium" onClick={firstButtonClickHandler}>
                                {firstButton}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default Modal;
