export const PLATFORM_USER_MANNUAL = "https://venupay.atlassian.net/wiki/spaces/VCS/pages ";
export const DEFAULT_TIMEZONE_VALUE = "US/Pacific";
export const DEFAULT_TIMEZONE = "Pacific Time- US & Canada";
export const BANK_ACCOUNT_ACTIVITY = "add,view,delete";
export const DEFAULT_PAGE_LIMIT = 10;
export const PAGINATIONS_OPTIONS = [4, 10, 20, 30];
export const SHORT_DEFAULT_PAGE_LIMIT = 4;
export const LOADING_COUNT_VARIABLE = -1;
export const DEBOUNCER_TIMER = 1500;
export const CHECK_STATUS = "DEREGISTERED";
export const MAX_FILE_SIZE = 1 * 1024 * 1024;
export const CHECK_CUSTOMER_DRIVEN_ACTION = "CUSTOMER_DRIVEN";
export const CHECK_CLOSED_STATUS = "CLOSED";
export const CHECK_PENDING_STATUS = "PENDING";
export const DEREGISTRATION_SUCCESS_MESSAGE = "Deregistration process has started.";
export const ADD_BANK_ACTIVITY_NAME="OPENED_FINICITY"
