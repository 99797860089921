/* eslint-disable @typescript-eslint/ban-types */
import { Rating, RatingChangeEvent } from "primereact/rating";
import { useEffect, useState } from "react";

/**
 * Type representing the properties for the RatingInput component.
 */
type RatingInputType = {
    id: string;
    label: string;
    name: string;
    value: string;
    className?: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onChange?: Function;
    isError?: boolean;
    errorMessage?: string;
};

/**
 * RatingInput component allows users to provide a rating.
 * @param {RatingInputType} props - Object containing the following properties:
 *  - id: The unique identifier for the rating input.
 *  - name: The name attribute for the input.
 *  - label: The label for the rating input.
 *  - value: The current selected rating value.
 *  - onChange: Function to call when the rating changes.
 *  - className: Optional additional CSS classes for styling the component.
 *  - isError: Boolean indicating if there is an error.
 *  - errorMessage: The error message to display if there is an error.
 * @returns {JSX.Element} The rendered RatingInput component.
 */
const RatingInput = ({ id, name, label, value, onChange = () => null, className, isError, errorMessage }: RatingInputType) => {
    const [currentValue, setCurrentValue] = useState<any>(value);
    const handleOnChange = (e: RatingChangeEvent) => {
        onChange(id, e.value);
    };
    useEffect(() => {
        setCurrentValue(value);
    }, [value]);
    const getRatingClassName = () => {
        if (currentValue <= 2) {
            return "rating-low"; // For 1 or 2 stars
        } else if (currentValue === 3) {
            return "rating-medium"; // For 3 stars
        } else if (currentValue >= 4) {
            return "rating-high"; // For 4 or 5 stars
        }
        return ""; // Default, no additional class
    };
    return (
        <div className={`w-full text-start ${className}`}>
            {label && <div className="font-medium mb-5 text-lg max-2xl:mb-2 max-2xl:text-base">{label}</div>}
            <div>
                <span className="w-full p-input-icon-left">
                    <Rating name={name} id={id} value={currentValue} onChange={(e: RatingChangeEvent) => handleOnChange(e)} cancel={false} className={getRatingClassName()} />
                </span>
            </div>
            {isError && errorMessage && <div className="text-errorMessageColor text-left text-sm mb-2 mt-2">{errorMessage}</div>}
        </div>
    );
};

export default RatingInput;
