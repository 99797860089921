/* eslint-disable @typescript-eslint/ban-types */
import { Fragment, useEffect } from "react";
import ReactHotkeys from "react-hot-keys";

/**
 * Type for the IconModal component's props.
 *
 * @type {IconModalType}
 * @property {boolean} show - Determines whether the modal is visible.
 * @property {any} children - Content to be displayed inside the modal.
 * @property {string} [className] - Additional CSS classes for the modal.
 * @property {Function} setShowModal - Function to control the visibility of the modal.
 * @property {Function} [onCloseModal] - Function to be called when the modal is closed.
 * @property {string} [title] - Title of the modal.
 * @property {string} [firstButton] - Text for the first button in the modal.
 * @property {Function} [onClickFirstButton] - Function to be called when the first button is clicked.
 * @property {string} [icon] - CSS class for the icon to be displayed in the modal.
 */
export type IconModalType = {
    show: boolean;
    children: any;
    className?: string;
    setShowModal: Function;
    onCloseModal?: Function;
    title?: string;
    firstButton?: string;
    onClickFirstButton?: Function;
    icon?: string;
};

/**
 * IconModal component for displaying a modal with an icon, title, and buttons.
 *
 * @param {IconModalType} props - The props for the component.
 * @returns {JSX.Element} The rendered IconModal component.
 */
const IconModal = ({ show, children, setShowModal, onCloseModal = () => null, title = "", className = "", firstButton, onClickFirstButton = () => null, icon }: IconModalType) => {
    useEffect(() => {
        setShowModal(show);
    }, [show]);

    /**
     * Closes the modal and calls the onCloseModal function.
     */
    function closeModal() {
        setShowModal(false);
        onCloseModal();
    }

    if (!show) {
        return <div></div>;
    }

    /**
     * Handles the click event for the first button.
     * Closes the modal and calls the onClickFirstButton function.
     */
    const firstButtonClickHandler = async () => {
        closeModal();
        onClickFirstButton();
    };

    return (
        <Fragment>
            <ReactHotkeys keyName="esc" onKeyDown={closeModal} />
            <button className="z-[2000] fixed inset-0 bg-black opacity-30" onClick={closeModal}></button>
            <div className={`z-[2000] fixed m-auto text-center top-1/2 mt-12 bg-white rounded-2xl -translate-y-1/2 shadow-modal inset-x-0 w-[800px] max-h-[544px] ${className}`}>
                <div className="text-center max-h-[90vh] 2xl:py-12 py-10 overflow-auto m-auto text-black w-full relative">
                    <div className="2xl:pb-8 pb-5">
                        <i className={`${icon}`} />
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="2xl:text-[44px] text-secondaryTextColor font-extrabold w-full text-4xl">{title}</div>
                    </div>
                    <div className="2xl:py-8 py-5">{children}</div>
                    {firstButton && (
                        <div className="flex justify-center">
                            <button
                                className="whitespace-nowrap py-4 w-2/5 mt-12 2xl:mt-0 text-lg mx-auto rounded-full font-semibold max-2xl:py-2.5 max-2xl:font-medium max-2xl:mt-8 bg-buttonBgColor text-white"
                                onClick={firstButtonClickHandler}
                            >
                                {firstButton}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default IconModal;
