import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import { Message } from 'primereact/message';
import { setIsMessage, setMessage } from '../redux/slices/stepperSlice';

/**
 * Component for displaying status messages using Redux state.
 * 
 * @param className - Optional additional class names for styling.
 * @returns A JSX element displaying the status messages using redux.
 */
const StatusMessagesComponentRedux = ({ className }: { className?: string }) => {
    const dispatch = useDispatch();
    const { isMessage, message, isSuccess } = useSelector((state: RootState) => state.stepperModal);
    
    const [localMessages, setLocalMessages] = useState<{ text: string; type: 'success' | 'error' }[]>([]);

    useEffect(() => {
        if (isMessage && message) {
            const messageType = isSuccess ? 'success' : 'error';
            setLocalMessages(prevMessages => [...prevMessages, { text: message, type: messageType }]);

            const timer = setTimeout(() => {
                setLocalMessages(prevMessages => prevMessages.slice(1));
                dispatch(setIsMessage(false));
                dispatch(setMessage(''));
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [isMessage, message, isSuccess, dispatch]);

    /**
     * Handler for closing a message when the close button is clicked.
     * 
     * @param index - The index of the message to close.
     */
    const onCloseClickHandler = (index: number) => {
        setLocalMessages(prevMessages => prevMessages.filter((_, i) => i !== index));
    };

    return (
        <div className={className}>
            {localMessages.map((msg, index) => (
                <div key={`${msg.text}-${msg.type}-${index}`} className={`message-container flex items-center mb-2 ${msg.type === 'error' ? 'text-errorMessageColor' : 'text-successMessageColor'}`}>
                    <Message severity={msg.type} className="w-full justify-start text-wrap pr-8 text-left" text={msg.text} />
                    <button
                        className={`pi pi-times -ml-8 ${msg.type === 'error' ? 'text-errorMessageColor' : 'text-buttonBgColor'}`}
                        onClick={() => onCloseClickHandler(index)}
                        aria-label="close-message"
                    ></button>
                </div>
            ))}
        </div>
    );
};

export default StatusMessagesComponentRedux;
